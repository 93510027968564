@use "../../../Abstract/" as a;

.dndCard {
  display: flex;

  gap: 15px;

  .imgContainer {
    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
    }
  }

  .details {
    // margin-top: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    // gap: 9px;

    .details-name-option {
      width: 100%;
      display: flex;
      justify-content: space-between;
      justify-content: center;

      .nameOption {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: auto 0;

        h2 {
          width: 100%;
          padding: 0;
          margin: 0;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          color: #1d9ce6;
        }
      }

      .option {
        margin-top: 5px;
        display: flex;
        gap: 10px;
        color: #232323;

        .iconOption {
          cursor: pointer;
          padding: 10px 0px;
        }

        .verticalEllipsis {
          padding: 7px 5px;
          margin: auto;
        }

        .verticalEllipsis:focus {
          border: none;
        }
      }
    }

    .cityWorkArrangement {
      font-family: a.$lato;
      flex-wrap: wrap;
      display: flex;
      justify-content: space-between;
      color: a.$dblue3;
      font-size: 14px;
      div {
        display: flex;
        gap: 7px;
        align-items: center;

        p {
          font-weight: 500;
          margin: auto;
        }
      }

      #item-status {
        display: none;
      }
    }

    .card-status {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      word-break: break-all;
      white-space: pre-line;

      p {
        flex: wrap;
        min-width: fit-content;
        margin-right: 5px;
        margin-bottom: 0;
      }
    }

    .calendar-time-container {
      display: flex;
      gap: 10px;
      .calendar {
        margin-top: 5px;
        font-family: a.$lato;
        color: a.$dblue3;
        display: flex;
        gap: 5px;
        font-weight: 500;
        font-size: 14px;

        .icon {
          margin-top: 3px;
        }
      }
    }
  }
}

.view_resume_file {
  width: 100%;
  min-height: 500px;
}
