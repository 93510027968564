.applicant-tabs__per_title {
  cursor: pointer !important;
  padding: 0.5rem;

  &:hover {
    border-radius: 10px 10px 0 0;
    padding: 0.8rem;
    background-color: #6f64f8;
    color: #fff;
    text-decoration: underline;
    text-underline-offset: 23.4px;
  }
}

.atsActive {
  border-radius: 10px 10px 0 0;
  padding: 0.8rem;
  background-color: #1d9ce6;
  color: #fff !important;
  text-decoration: underline;
  text-underline-offset: 23.4px;
}

.select-container {
  .form-select {
    font-weight: bold;
  }

  label {
    color: #3085d6;
    font-weight: bold;
  }
}

.table__recommendation {
  thead > tr > td {
    background-color: #eae9f0 !important;
  }
  td {
    background-color: #fff !important;
  }
  .ai-scores {
    height: 40px;
    width: 40px;
    border: none;
    background-color: #3085d6;
  }
}
.ai-scores__modal {
  .profile-picture {
    width: 70px;
    height: 70px;
  }

  .hr__container {
    hr {
      width: 100%;
      padding: 0px;
      margin: 9px 0px 20px;
    }
  }

  .regenarate-button {
    border: none;
    background-color: #3085d6;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
  }

  .modal-body {
    padding: 17px 39px 0px;
  }

  .information__container {
    h3 {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 20px;
    }

    p {
      font-family: "Lato";
      font-weight: 400;
      font-size: 14px;
    }
  }

  #exampleModal {
    position: fixed;
    top: 30%;
  }

  .ai-scores__cotainer {
    margin-bottom: 20px;
    .screen-score__circle {
      max-height: 90px;
      max-width: 90px;
      text {
        font-size: 26px;
        color: #30adf3 !important;
        font-weight: 700;
      }
    }

    h5 {
      color: #1d9ce6;
      font-weight: 800;
    }
  }

  .fa-thumbs-up {
    color: rgb(0, 0, 0);
    -webkit-text-stroke-width: 2px !important;
    -webkit-text-stroke-color: red !important;
  }
}
