.fallback-spinner {
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.loading {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
