@use "../../../Abstract/" as a;

.privacyPolicy {
  padding: 30px 0;
  font-family: a.$rubik;
  background-color: #fff;

  h1 {
    font-weight: 600;
    margin-bottom: 20px;
    color: a.$violet3;
    font-size: 48px;
  }

  h2 {
    margin: 10px 0;
    color: a.$dblue;
    font-size: 20px;
    font-weight: 600;
  }

  h3 {
    margin: 10px 0;
    color: a.$dblue;
    font-size: 22px;
    font-weight: 600;
  }

  p {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
  }

  ul {
    display: block;
    list-style-type: disc;
  }

  .text-indent {
    text-indent: 1.3em;
  }

  a {
    color: a.$lblue4;
    text-decoration: none;
  }

  .text-underline {
    color: a.$black;
    text-decoration: underline;
    text-decoration-color: a.$gray2;
  }

  @media screen and (max-width: 600px) {
    .policy--img {
      display: none;
    }
  }
}
