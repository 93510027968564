@use "../../../Abstract/" as a;

.subscriberCard {
  font-family: a.$montserat;
  display: flex;
  align-items: center;

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
  //   gap: 50px;
  //   justify-content: space-between;

  .subscriberDetails {
    // width: 100%;
    display: flex;
    gap: 30px;
    justify-content: left;
    // height: 100%;

    @media screen and (max-width: 992px) {
      width: 100%;
    }

    .subscriberImage {
      img {
        height: 60px;
        width: 60px;
        margin-bottom: 20px;
      }
    }

    h3 {
      font-size: 20px;
      font-weight: 700;
      color: a.$blue;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      color: #010536;
    }
  }

  .vl {
    border-left: 1px solid #efefef;
    // height: 100%;
    // height: 500px;

    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .additionalComponents {
    padding: 0 0px 0 30px;
    width: 100%;
    display: flex;
    align-items: center;

    @media screen and (max-width: 992px) {
      padding: 0px;
    }
  }
}
