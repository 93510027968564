.ats_filter_container {
  display: flex;
  gap: 50px;

  .ats_filter_section {
    min-width: 200px;

    h5 {
      font-size: 20px;
      font-weight: 600;
      font-family: "Lato", sans-serif;
      color: #3085d6;
    }

    input,
    select {
      min-width: 200px;
      border: solid 1px #cacaca;
      border-radius: 10px;
      padding: 8px 10px;
    }
  }
}

.keyword_right_container {
  display: flex;
  justify-content: flex-end;
}

.custom_ats_table {
  text-align: center;

  thead {
    text-align: center;
    font-family: "Poppins", sans-serif;

    th {
      background-color: #eae9f0 !important;
    }
  }

  tbody {
    td {
      background-color: #fff;
    }
  }
}
