@use "../../../Abstract/" as a;

.viewresume_certificate {
  min-height: 180px;
  max-width: 250px;
  overflow-y: auto;
  font-family: a.$poppins;

  .certificate_content {
    font-size: 16px;
    margin: 15px 0;

    h5 {
      margin: 0;
      font-weight: 600;
      font-size: 16px;
    }

    p {
      margin: 0;
      font-weight: 500;
    }
  }
}
