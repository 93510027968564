.full-width-height {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-top: 80px;
}

.loginAdminNXTImage {
  height: 155px;
  width: 438px;

  @media screen and (max-width: 768px) {
    height: 100%;
    width: 100%;
  }
}

.loginAdminACQImage {
  height: 85.45px;
  width: 313.1px;

  @media screen and (max-width: 768px) {
    height: 100%;
    width: 100%;
  }
}
