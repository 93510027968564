@use "../../Abstract/" as a;

.adminNav {
  color: a.$white;
  background-color: #6f64f8;

  .navbar-toggler {
    background-color: white;

    &:focus {
      box-shadow: none;
    }
  }
  .date,
  .welcomeUser {
    color: a.$white;
    font-family: a.$poppins;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    word-break: break-all;
    white-space: pre-line;
    display: flex;
    gap: 10px;
    span {
      font-family: a.$poppins;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .date:hover {
    color: a.$white;
  }

  .welcomeUser {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .dropdown-menu {
    left: -70px !important;
  }
}
