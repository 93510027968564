@import "../Modal/CommonModalStyle.scss";

.modal-body-container {
  .logo-company-name {
    // display: flex;
    // gap: 10px;

    .logo-container {
      background-color: #ffff;
      border: 1px solid #e1e6eb;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;

      .icon {
        color: #c2c2c2;
        padding: 10px 20px;
      }
    }
  }
  .start-end-date {
    display: flex;
    gap: 10px;
    // margin-bottom: 15px;

    div {
      width: 100%;
    }

    .date-checkbox .checkbox {
      label {
        margin-left: 5px;
      }
    }
  }
  .start-end-msg {
    margin-top: -18px;
    .error-msg {
      display: block;
      font-family: "Lato", sans-serif;
      font-style: italic;
      font-weight: 600;
      font-size: 12px !important;
      color: #e80000 !important;
      // margin: 2px auto;
    }
  }
}
