.prod-hero {
  margin-top: 80px;
  background-image: url("../../images/bg-product.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 800px;
  width: 100%;
  font-family: "Rubik";

  @media (max-width: 1440px) {
    background-position: 100% 100%;
  }
  @media (max-width: 1280px) {
    background-position: -600px 100%;
  }
  @media (max-width: 600px) {
    height: 300px;
    background-position: -244px 0px;
  }

  h2 {
    font-size: clamp(1.5rem, 6.5vw, 5rem);
    color: #fff;
    line-height: 1.2;
    font-weight: 700;
  }

  h3 {
    font-size: clamp(1rem, 2.5vw, 2.5rem);
    font-family: "Inter", sans-serif;
    color: #fff;
  }

  .btn-subscribe {
    border-radius: 20px;
    border: 0;
    background-color: #ff4df8;
  }
}

.subs-container {
  height: 75px;
  background-color: #4cc6f3;

  .btn-subscribe {
    border-radius: 20px;
    border: 0;
    background-color: #ff4df8;
  }
}
