.about-apply {
  background-color: #ffffff;
  padding: 30px 0;

  @media screen and (max-width: 768px) {
    display: flex;
    padding: 30px;
  }
  .apply-body {
    display: flex;
    justify-content: center;
    gap: 20px;

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }

    .apply-img-container-post {
      display: flex;
      justify-content: center;
      padding: 150px 50px 50px 50px;
      background-image: url("../../images/About/postJob.png");
      background-position: center;
      background-size: cover;

      button {
        background-color: #01b5e0;
        border: none;
        border-radius: 30px;
        color: #ffffff;
        font-size: 18px;
        padding: 10px 20px;
        min-width: 200px;
      }
    }

    .apply-img-container-apply {
      display: flex;
      justify-content: center;
      padding: 150px 50px 50px 50px;
      background-image: url("../../images/About/apply.png");
      background-position: center;
      background-size: cover;

      button {
        background-color: #8046fa;
        border: none;
        border-radius: 30px;
        color: #ffffff;
        font-size: 18px;
        padding: 10px 20px;
        min-width: 200px;
      }
    }
  }
}
