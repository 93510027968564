.joblistsidebar-container {
  width: 268px;
  margin: 0;
  padding: 20px;
  border: 1px solid #00000040;
  border-width: 1px 0px 0px 0px;
  box-shadow: 0px 2px 4px 0px #00000040;

  background: #ffffff;

  .image-container {
    width: 107px;
    height: 107px;
    border-radius: 50%;
    margin-bottom: 20px;
    display: flex;
    overflow: hidden;

    .icon {
      margin: auto;
      font-size: 60px;
      color: #aeaeae;
    }

    img {
      background: #d9d9d9;
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }

    // .errorImg {
    //   padding: 20px; // Apply padding only to images with the errorImg class
    // }
  }

  h1 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #232323;
  }

  p {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #232323;
  }
}

@media screen and (max-width: 992px) {
  .joblistsidebar-container {
    width: 100%;
  }
}
