@use "../../Abstract/" as a;

.whiteContainer {
  // height: 100%;
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 3px 10px 0px #bfbfbf29;
  border-radius: 20px;
  margin: 10px; //   overflow: hidden;

  .applicant-tabs__title {
    display: flex;
    gap: 20px;
    padding: 0 15px 0px 15px;
    border-bottom: 1px solid #cacaca;

    .applicant-tabs__per_title {
      padding: 0.8rem;
      font-family: a.$poppins;
      font-size: 18px;
      font-weight: 600;
    }

    .font-circle {
      height: 18px;
      width: 18px;
      position: relative;
      border-radius: 50%;
      border: solid;
      border-width: 1px;
      font-size: 0.7rem;
    }
  }
}

.ats_filter_table_cont {
  background-color: #fff;
  padding: 30px 0;
  box-shadow: 0px 3px 10px 0px #bfbfbf29;
  border-radius: 20px;
  margin: 10px;

  .ats_filter_table {
    max-height: 300px;
    overflow-y: scroll;
  }
}
