.st-work-history-container {
  width: 100%;
  height: 100%;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #00000040;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;
  margin: 0 0 20px 0;

  h6 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #003763;
  }

  label {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #333333;
  }

  .header-container {
    display: flex;
    justify-content: space-between;

    button {
      width: 114px;
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      color: #ffffff;
      padding: 0;
      background: #efbb20;
      border-radius: 3px;
      border: none;

      .pencil-icon {
        margin-right: 3px;
      }
    }
  }

  //   p {
  //     line-height: 10px;
  //   }
  .cv-list {
    margin-top: 20px;
    p {
      line-height: 10px;
    }

    .remove-icon-container {
      display: flex;
      gap: 10px;

      .remove-work-container {
        cursor: pointer;
      }
      margin-top: -5px;
      .remove-work {
        width: 10px;
        height: 11px;
        color: #333333;
      }

      span {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #333333;
      }

      button {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        color: #ffffff;
        background: #efbb20;
        border-radius: 3px;
        border: none;
      }
    }
  }
}
