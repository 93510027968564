@use "../../Abstract/" as a;

.errorMessage {
  font-family: a.$inter;
  font-weight: 400;
  font-size: 12px;
  color: a.$red;
  font-style: italic;
  white-space: pre-line;
}

.successMsg {
  color: a.$green;
}
