.dashboardContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: calc(100vh - 76px);
  overflow-y: scroll;

  .listAndChart {
    display: flex;
    gap: 10px;

    @media screen and (max-width: 772px) {
      flex-direction: column;
    }

    .companyJobPostedList,
    .chart {
      background-color: #fff;
      padding: 10px 20px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media screen and (max-width: 772px) {
        flex-direction: column;
      }
    }
  }
}
