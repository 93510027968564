.add-job-container {
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #00000040;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;

  .add-job-content {
    h1 {
      color: #003763;
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 800;
      font-family: "Lato", sans-serif;
    }

    button {
      margin-top: 10px;
      background: #007bb6;
      border: none;
      border-radius: 5px;
    }

    .faPlusIcon {
      margin-right: 5px;
    }
  }
}

.update-profile-container {
  width: 100%;
  height: 100%;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #00000040;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;

  .company-profile {
    h1 {
      color: #003763;
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 800;
      font-family: "Lato", sans-serif;
    }
  }

  .entries-search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    input,
    select {
      margin: 0 10px;
    }

    label {
      font-family: "Lato", sans-serif;
      font-weight: 500;
      font-size: 16px;
    }

    .entries-container {
      display: flex;
      align-items: center;
    }

    .search-container {
      display: flex;
      align-items: center;
    }
  }

  .joblist-table-container {
    width: 100%;
    max-height: 1000px; /* Set the maximum height of the table container */
    overflow-y: auto; /* Enable vertical scrolling when needed */
    // display: flex;
    flex-direction: column; /* Adjust flex-direction to column */
    justify-content: flex-start; /* Align content to the top */
    align-items: center;
    margin: 30px 0;

    .joblist-table {
      // width: 100%;
      th {
        padding: 10px 10px 0 10px;
        background: #e1e7ff;
        align-items: center;

        label {
          align-items: center;
        }
      }

      td {
        vertical-align: middle;
      }

      .jobID {
        font-weight: bold;
      }

      .sort-icon {
        margin-right: 5px;
        cursor: pointer;
        color: #797b85;
      }

      .view-applicants-label {
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #007bb6;
        cursor: pointer;
      }

      .open-status,
      .draft-status,
      .inactive-status,
      .close-status {
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 22px;
        border-radius: 3px;
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-size: 12px;
      }

      .open-status {
        background: #0e932b;
      }

      .draft-status {
        background: #efbb20;
      }
      .inactive-status {
        background: #e80000;
      }

      .close-status {
        background: #808080;
      }

      .jobtitle-container {
        display: block;
        font-family: "Lato", sans-serif;
        font-weight: 700;
        font-size: 16px;

        .job-title-text {
          cursor: pointer;
        }
        .job-title-text:hover {
          color: #3085d6;
          cursor: pointer;
        }

        .location-container {
          display: flex;
          gap: 20px;
          label {
            font-family: "Lato", sans-serif;
            font-weight: 400;
            font-size: 14px;
            margin: 0 5px;
          }

          svg {
            color: #004276;
          }
        }
      }

      // table action
      .action-container {
        display: flex;
        gap: 10px;
        font-size: 15px;
        color: #ffffff;

        .edit-container {
          width: 36px;
          height: 32px;
          background-color: #3085d6;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          cursor: pointer;
        }

        .delete-container {
          width: 36px;
          height: 32px;
          background-color: #e80000;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
  }

  .entries-pagiation-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // background-color: #007bb6;

    .entries-label {
      display: flex;
      align-items: center;
      font-family: "Lato", sans-serif;
      font-weight: 500;
      font-size: 16px;
      color: #6f6f6f;
    }

    button {
      background: #efbb20;
      border: none;
    }
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination a {
  padding: 6px 12px;
  margin: 0 3px;
  border: 1px solid #ccc;
  color: #333;
  cursor: pointer;
}

.pagination a.active {
  background-color: #007bff;
  color: #fff;
}

.pagination a:hover {
  background-color: #f5f5f5;
}
