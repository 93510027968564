.about-banner {
  padding-top: 80px;
  img {
    width: 100%;

    @media screen and (max-width: 768px) {
      height: 100%;
    }
  }
}
