.admin {
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f2f2f2;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 15px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #d9d9d9;
  }

  display: flex;
  height: 100vh;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
  // overflow: hidden;

  .sidebar {
    width: 280px;
    @media screen and (max-width: 1000px) {
      display: none;
    }
    // height: auto;
  }

  .navbarContent {
    width: calc(100% - 229px);
    overflow-y: scroll;
    // position: relative;

    @media screen and (max-width: 1000px) {
      width: 100%;
    }

    .pageContainer {
      // height: 100%;
      padding: 60px 10px;
      width: 100%;
      background: #f9f9f9;
    }

    .fullheight {
      height: 100%;
    }
  }
}
