@use "../../../Abstract/" as a;
@import "../../../layout/ContainerIndex/containerIndex";

// Form
.loginForm {
  font-family: a.$inter;

  p {
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    color: a.$dblue;
  }

  p {
    margin: 10px 0 20px;
    font-size: 16px;
    font-weight: 400;
  }

  .forgotPassButton {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    margin: 20px 0;
    p {
      font-family: a.$inter;
      font-weight: 400;
      font-size: 14px;
      color: a.$lblue4;
      text-align: center;
      cursor: pointer;
    }
  }
}

// Option
.loginOption {
  .buttonSignupContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    h4 {
      font-size: 14px;
      font-family: a.$inter;
      font-weight: 400;
      color: a.$dblue;
      cursor: pointer;
    }
  }

  #fbContainerLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 15px;
    border: 1px solid #dadce0;
    border-radius: 50%;
  }

  a {
    font-size: 14px;
    font-family: a.$inter;
    font-weight: bold;
    color: a.$violet2;
    text-decoration: none;

    // &:hover {
    //   text-decoration: underline;
    // }
  }
}

// Captcha
.captcha {
  margin: 20px 0px 30px 0;
}
