.search-filter-container {
  .search-filter-content {
    width: 100%;
    height: 100%;
    padding: 30px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    display: flex;
    gap: 30px;

    @media screen and (max-width: 992px) {
      flex-direction: column;
      gap: 15px;
      // button {
      //   margin-top: 10px;
      //   width: 100%;
      // }
    }

    .search-icon {
      position: relative;
      flex: 1 1 0;

      display: flex;
      align-items: center;
      position: relative;
    }

    .icon {
      position: absolute;
      left: 8px;
      top: 12px;
    }

    input {
      //   width: 100%;
      flex: 1 1 0;
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      padding: 10px 10px 10px 40px;
    }

    input:focus {
      outline: none !important;
      box-shadow: none;
    }

    .searchIcon {
      position: absolute;
      left: 10px; // Adjust the left position to control the icon's placement
      top: 50%;
      transform: translateY(-50%);
      color: #aaa;
    }

    button {
      padding: 10px 20px;
      background: #efbb20;
      border-radius: 3px;
      border: none;
    }

    .landingPageBtn {
      padding: 10px 20px;
      background: #7352e9;
      border-radius: 50px;
      border: none;
    }
  }
}
