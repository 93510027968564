.pageContainer,
.manage--job,
.create--job,
.chatbox--box {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .dashboardChart {
    display: flex;
    gap: 10px;
  }

  .innerContainer {
    background-color: #fff;
    border-radius: 5px;
    padding: 30px;

    .title {
      margin-bottom: 30px;
      h1 {
        font-family: "Montserrat", sans-serif;
        font-size: 22px;
        font-weight: 600;
        color: #1d9ce6;
      }
    }

    .pageContent {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }

  // manage job title
  .manage-job__main_title {
    color: #1d9ce6;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
  }

  .manage-job__bg_blue {
    background-color: #1d9ce6;
    border: 1px solid #1d9ce6;
    font-family: Poppins;
  }

  // manage job table
  .manage-job__table {
    tbody tr td {
      background-color: #fff;
    }
    tbody tr td:nth-child(2) {
      color: #007bb6;
    }
    // color for job status
    .manage-job__green {
      color: #0e932b;
    }
    .manage-job__yellow {
      color: #efbb20;
    }
    .manage-job__red {
      color: #d10000;
    }
    .manage-job__gray {
      color: #606060;
    }
    .manage-job__blue {
      color: #1d9ce6;
    }
  }

  // create job titles
  .create-job__main_title {
    color: #1d9ce6;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
  }

  .create-job__title {
    color: #1d9ce6;
    font-family: Lato;
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
}
