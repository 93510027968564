// large text = 40px || 2.5rem
// mid text = 24px || 1.5rem
// normal text = 16px || 1rem

@mixin large-text {
  font-size: 2.5rem;
}
@mixin mid-text {
  font-size: 1.5rem;
}
@mixin normal-text {
  font-size: 1rem;
}

// navbar
.nav__gigster {
  font-family: "Rubik", sans-serif;
  .nav__link a {
    color: #003763;
    font-size: 20px;
  }

  .navbar-nav .nav-link.active {
    color: #00eaea;
  }
}

// section 1
.discover_page {
  height: 100vh;
  background-image: url("../../images/Gigster/img/Ellipse-10.png");
  background-size: 100% 100%;
  .discover__page-img {
    height: 85%;
    position: absolute;
    bottom: 1.5%;
    left: 10%;
  }
  .discover__page-intro {
    position: absolute;
    right: 20%;
    top: 40%;
    @include large-text();
    color: #fff;
    .discover__page-imgtext {
      height: 60px;
      width: 170px;
    }
    p {
      font-weight: 600;
    }
    button {
      background-color: #209be6;
      color: #fff;
      border-radius: 30px;
    }
  }
}

// section 2
.find__gigster {
  height: 40vh;
  position: relative;
  .fing__gigster-text {
    height: 40vh;
  }
  h3 {
    @include large-text;
    color: #c94fd3;
    font-weight: 600;
  }
  p {
    @include mid-text;
    color: #003763;
    font-weight: 600;
  }
  img {
    height: 150%;
    top: -80px;
    position: absolute;
  }
}

// section 3
.yellow__circle {
  background-image: url("../../images/Gigster/img/Ellipse-12.png");
  background-size: 100% 90%;
  background-repeat: no-repeat;
  .formal__guy {
    height: 100%;
    width: 100%;
  }
  .formal__guy-text {
    height: 100%;
    display: flex;
  }
  p {
    @include mid-text;
    font-weight: 600;
  }
  li {
    list-style: none;
    margin-left: 1rem;
  }
  //   joining free section
  .joining__free {
    .joining__logo {
      margin-top: 1rem;
      margin-right: 1rem;
      width: 50px;
      height: 50px;
    }
    h3 {
      @include large-text();
    }
    h4 {
      @include mid-text();
    }
    p {
      @include normal-text();
    }
  }

  //   find talent
  .find__talent,
  .discover__work {
    .find__talent-text {
      height: 100%;
      font-weight: 600;

      button {
        color: #fff;
        border-radius: 30px;
        background-color: #aa81c3;
        border: none;
      }
    }
    .find__talent-bg {
      background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.8883928571428571) 5%,
          rgba(255, 255, 255, 0.6166841736694677) 35%,
          rgba(255, 255, 255, 0) 50%
        ),
        url("../../images/Gigster/img/img-long-first.png");
      background-size: cover;
      width: 100%;
      height: 400px;
    }

    .discover__work-bg {
      background: rgb(107, 180, 252);
      background-image: linear-gradient(
          90deg,
          rgba(107, 180, 252, 1) 50%,
          rgba(255, 255, 255, 0) 50%
        ),
        url("../../images/Gigster/img/woman-with-headset-having-video-call-laptop.png");
      background-size: cover;
      width: 100%;
      height: 400px;
      .discover__work-text {
        font-weight: 600;
        height: 100%;
        button {
          color: #003763;
          border-radius: 30px;
        }
      }
    }
    h3 {
      @include large-text();
    }
    p {
      @include normal-text();
    }
  }
}

footer {
  height: 300px;
  background-color: #6bb4fc;
  color: #fff;
  .footer__icon {
    li {
      margin: 0.5rem;
      @include large-text();
    }
  }
  ul li {
    list-style: none;
    margin: 1rem;
    @include normal-text();
  }
  span {
    padding: 0 20px 0 20px;
    @include normal-text();

    &:nth-child(1) {
      padding-left: 0;
      border-right: 1px solid #fff;
    }
  }
}
