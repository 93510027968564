.manage-user-container {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 30px;

  h6 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #003763;
  }

  h5 {
    color: #57606a;
    -webkit-transform: scale(1.8, 1.2); /* Safari and Chrome */
    -moz-transform: scale(1.8, 1.2); /* Firefox */
    -ms-transform: scale(1.8, 1.2); /* IE 9 */
    -o-transform: scale(1.8, 1.2); /* Opera */
    transform: scale(1.8, 1.2); /* W3C */
  }

  .subscription-content {
    display: flex;
    width: 100%;
    gap: 20px;

    div {
      width: 100%;
      label {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #000000;
      }
    }
  }
  .card-free {
    box-shadow: inset 0px 8px 0px 15px #8c64d2;
    border-radius: 2rem;
  }
  .btn-green {
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    background: linear-gradient(0deg, #0e932b, #0e932b),
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(255, 255, 255, 0) 100%
      );

    &:hover {
      color: #fff;
    }
    &:active {
      color: #fff;
    }
    &:focus {
      color: #fff;
    }
  }

  .card-shadow {
    box-shadow: 0px 12px 28px 0px #8c959f4d !important;
  }

  .card__text-violet {
    color: #8046fa;
  }

  .config-subs,
  .order-summary {
    border-radius: 8px;
    box-shadow: 0px 12px 28px 0px #8c959f4d;
  }

  .checkout-page {
    border-radius: 8px 8px 0 0;
    box-shadow: 0px 12px 28px 0px #8c959f4d;
  }

  .subs-features {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
  }

  input[type="number"] {
    height: 20px;
    border-radius: 5px;
    border: 1px solid #e1e6eb;
    box-shadow: 0px 2px 4px 0px #00000040;
  }

  .subs__history-tbody {
    td {
      background-color: #fff;
    }

    .subs__history-pending {
      background: #efbb20;
    }

    .subs__history-paid {
      background: #0e932b;
    }

    .subs__history-cancelled {
      background: #d10000;
    }

    .subs__history-renew {
      background: #1d9ce6;
    }
  }
}
