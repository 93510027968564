@mixin large-text {
  font-size: 2.5rem;
}
@mixin mid-text {
  font-size: 1.5rem;
}
@mixin normal-text {
  font-size: 1rem;
}
@mixin default-txt {
  color: #003763;
  font-weight: 600;
}

.gigster__activate {
  background-color: #fff;
  h3 {
    @include large-text();
    @include default-txt();
  }
  h4 {
    @include mid-text();
    @include default-txt();
  }
  li {
    @include normal-text();
    @include default-txt();
    line-height: 1.8;
  }

  .btn-auth {
    background-color: #01b5e0;
    border-radius: 30px;
    color: #fff;
    font-weight: 600;
  }
  .btn-cancel {
    background-color: #d2d2d2;
    border-radius: 30px;
    color: #232323;
    font-weight: 600;
  }

  img {
    height: 100px;
  }
}
