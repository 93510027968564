.superAdminSidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border: #0000002b;
  box-shadow: #0000002b;
  // width: fit-content;
  padding: 15px;
  height: 100vh;
  gap: 10px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f2f2f2;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 15px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #d9d9d9;
  }

  .sidebarLinkContainer {
    img {
      width: 168px;
      height: 60px;
      margin-bottom: 25px;
    }

    .sidebarSection {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 5px;

      h1 {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        color: #1d9ce6;
        font-size: 20px;
      }
    }

    .sidebarLink {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #232323;
      // margin-left: 10px;
      border-radius: 5px;
      // background-color: #ececec;
      cursor: pointer;

      p {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        margin: 0;
        font-size: 20px;
      }

      .icon {
        margin: 0;
      }
    }
  }
}
