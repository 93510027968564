.companyList {
  h1 {
    margin: 10px 0;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
  }
  .companyListInner {
    .companyListcontent {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 7.5px 0;

      .companyListImgContainer {
        width: 39px;
        height: 39px;

        border-radius: 50%;

        img {
          width: 39px;
          height: 39px;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      h1 {
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
        color: #606060;
        margin: 0;
      }
    }
  }
}
