@use "../../Abstract/" as a;

.customModal {
  text-align: center;

  .cancel {
    background-color: rgb(172, 172, 172);
  }

  .modal-content {
    background-color: #fff;
    width: 327px;
    height: 342px;
    margin: auto;

    .modal-body {
      font-family: a.$lato;
      height: 100%;

      h1 {
        font-weight: 800;
        font-size: 40px;
        padding-top: 20px;
      }

      .messageContainer {
        margin-top: 20px;
        display: flex;
        height: 80px;
        justify-content: center;

        p {
          font-weight: 600;
          font-size: 20px;
          text-align: center;
        }
      }
    }

    .modal-header,
    .modal-footer {
      border: none;

      .modalBtns {
        width: 100%;
        gap: 10px;
      }
    }

    .title {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: -60px;
      margin-left: 20px;

      .iconContainerCenter {
        width: 95px;
        height: 95px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: a.$white;
        margin-right: -5px;

        .icon {
          font-size: 70px;
        }
      }

      .iconContainer {
        width: 95px;
        height: 95px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: a.$white;
        margin-left: -10px;

        .icon {
          font-size: 70px;
        }
      }

      .green {
        background-color: a.$green;
      }

      .red {
        background-color: a.$red2;
      }

      .yellow {
        background-color: a.$yellow;
      }
    }
  }
}
