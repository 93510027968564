.background-imagePost-talent {
  background-image: url("../../../../../public/images/acquiro-element-bg.jpg") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  .header-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
  }

  .header-fixed + .jobposting-banner {
    padding-top: 90px;
  }
  .search-container {
    margin-top: -40px;
    // z-index: 2;
  }
  .jobposting-body {
    gap: 50px;
    display: flex;
    margin: 50px auto;
    padding: 0 auto;

    .sidebar-container {
      top: 100px;
      position: sticky;
    }

    .talent-list-container {
      width: 100%;
      height: 100%;
      // display: inline-block;
    }
  }
  .content {
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 20px 0;

    .side-bar-menu {
      align-self: auto;
      width: fit-content;
      height: fit-content;
      margin-left: 18%;
    }
  }
}

@media screen and (max-width: 768px) {
  .background-imagePost {
    .jobposting-body {
      // gap: 50px;
      display: flex;
      flex-direction: column;
      // background-color: aqua;
      margin: 50px auto;
      padding: 0 25px;

      .sidebar-container {
        width: 100%;
        margin: auto;
      }
    }
  }
}
