.jobPostedList {
  h1 {
    margin: 10px 0;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
  }
  .jobPostedListcontent {
    display: flex;
    gap: 10px;
    padding: 5px 0;

    .jobPostedListImgContainer {
      width: 39px;
      height: 39px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 39px;
        height: 39px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    h1,
    p {
      font-family: "Montserrat", sans-serif;
      font-size: 20px;
      color: #606060;
      margin: 0;
    }

    p {
      font-size: 14px;
    }
  }
}
