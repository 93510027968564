.background-imagePost-talent-list {
  background-image: url("../../../../../public/images/acquiro-element-bg.jpg") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  .header-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
  }

  .header-fixed + .jobposting-banner {
    padding-top: 80px;
  }
  .search-container {
    margin-top: -40px;
    // z-index: 2;
  }
  .jobposting-body {
    margin: 50px auto;
    padding: 0 auto;

    .sidebar-container {
      top: 100px;
      position: sticky;
    }

    .talent-list-container {
      width: 100%;
      height: 100%;

      // display: inline-block;

      .joblist-load-more {
        display: flex;
        justify-content: right;

        button {
          background: #efbb20;
          border: none;
        }
      }
    }

    .talent__list--recommendation {
      // display: flex;
      // gap: 50px;
      // width: 100%;
      // flex-wrap: wrap;
      // flex-wrap: wrap;

      .talent__list--recommendation--filter {
        // min-width: 20%;

        .slider-container {
          border: 1px solid #ddd;
          background-color: white;
          padding: 20px 20px 0px 20px;

          .apply-btn {
            cursor: pointer;
            text-align: right;
            margin-top: 30px;
            font-size: 13px;
            color: #0132dc;
          }
        }

        .filter__job {
          display: flex;
          flex-direction: column;
          gap: 15px;

          .filter--container {
            .filter--container--title {
              display: flex;
              justify-content: space-between;

              label {
                font-weight: 600;
                font-size: 20px;
                color: #6f6f6f;
                margin: 5px 0;
              }

              .filter--container--icon {
                cursor: pointer;
                margin: auto 0;
              }
            }

            select {
              width: 100%;
              border-radius: 3px;
              border: 1px solid #d9d9d9;
              padding: 5px;
            }

            .filter--input {
              width: 100%;
              border-radius: 3px;
              border: 1px solid #d9d9d9;
              padding: 5px;
            }
          }
        }
      }

      .talent__list--recommendation--section {
        width: 100%;
      }
    }
  }
  .content {
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 20px 0;

    .side-bar-menu {
      align-self: auto;
      width: fit-content;
      height: fit-content;
      margin-left: 18%;
    }
  }
}

@media screen and (max-width: 768px) {
  .background-imagePost {
    .jobposting-body {
      // gap: 50px;
      display: flex;
      flex-direction: column;
      // background-color: aqua;
      margin: 50px auto;
      padding: 0 25px;

      .sidebar-container {
        width: 100%;
        margin: auto;
      }
    }
  }
}
