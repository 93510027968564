.filter-list-container {
  // width: 100%;
  // height: 100%;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #00000040;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;
  margin: 0 0 20px 0;

  h6 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #003763;
  }

  label {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #3085d6;
  }

  .input-groups {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    div {
      width: 32.4%;
    }
  }
  .reset-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;

    .update-btn {
      background: #efbb20;
      font-size: 16px;
      font-family: "Lato", sans-serif;
      font-weight: 500;
      border: none;
    }

    .update-btn:hover {
      background: #efbb20;
    }
  }
}
