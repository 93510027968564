@mixin triangle-left {
  .triangle-left,
  .triangle-right {
    position: absolute;
    left: -12px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
}

@mixin triangle-right {
  .triangle-left,
  .triangle-right {
    position: absolute;
    right: 72px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
}

.background-imagePost {
  background-image: url("../../../public/images/acquiro-element-bg.jpg") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  .header-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
  }

  .header-fixed + .jobposting-banner {
    padding-top: 80px;
  }

  .jobposting-body {
    gap: 50px;
    display: flex;
    margin: 50px auto;
    padding: 0 auto;

    .sidebar-container {
      top: 100px;
      position: sticky;
    }
  }
}

.chat-assistant_container {
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: 100%;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f2f2f2;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 15px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #d9d9d9;
  }

  .errorSessionId {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    p {
      font-size: 23px;
    }

    button {
      padding: 10px 20px;
      background: #6f64f8;
      border: 0;
      color: #ffffff;
      margin-top: 10px;
      border-radius: 5px;

      &:hover {
        background: #6f64f8;
      }
    }
  }

  .chat-assistant {
    width: 100%;
    padding: 30px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2509803922);
    border-radius: 5px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2509803922);
    margin: 0 0 20px 0;

    h3 {
      font-weight: 600;
      font-size: 36px;
      color: #6f64f8;
    }

    h4 {
      font-weight: 500;
      font-size: 24px;
      color: #000;
    }

    p,
    span {
      font-weight: 500;
    }

    .chat-assistant_box {
      padding: 30px 30px 30px 30px;
      background-color: #f2f2f2;
      height: 500px;
      overflow: auto;

      .inactive {
        display: none !important;
      }

      .overlay {
        font-family: "Rubik";
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        border-radius: 5px;
        opacity: 1;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        background: rgb(0, 0, 0);
        background: rgba(0, 0, 0, 0.6); /* Black see-through */
        width: 100%;
        transition: 0.5s ease;
        font-size: 20px;
        padding: 20px;

        .overlay-txt {
          width: 25%;
          font-weight: 400 !important;
          border-radius: 10px;
          font-size: clamp(0.4rem, 2vw, 0.7rem);
          transition: 0.5s ease-in-out;
          p {
            margin-bottom: 0.5rem;

            a {
              color: #1d9ce6;
            }
          }

          @media (max-width: 1920px) {
            width: 20vw;
          }

          @media (max-width: 1440px) {
            width: 35vw;
          }

          @media (max-width: 1024px) {
            width: 60vw;
          }

          @media (max-width: 600px) {
            width: 100vw;
          }
        }
      }

      .chat-assistant__button-container {
        display: flex;
        flex-wrap: wrap;
        .button-container__buttons {
          border: 2px solid #6f64f8;
          border-radius: 5px;
          background-color: #fff;
          color: #6f64f8;
          margin: 10px;
          padding: 10px;
          font-weight: 470;
        }
      }

      .chat-assistant__ai {
        .chat-assistant__ai-logo {
          height: 38px;
          min-width: 38px;
          border-radius: 50%;
          background-image: url("../images/Logo/Brand/nexie.png");
          background-size: 100% 100%;
        }

        .chat-assistant__ai-text {
          color: #75868f;
          padding: 0.8rem;
          background-color: #fff;
          border-radius: 10px;
          margin-left: 1rem;
          border: 2px solid #f0f3f7;
          position: relative;

          .chat-assistant__link {
            color: #7e49ff;
            text-decoration: underline;
            font-weight: 600;
            text-transform: capitalize;
            cursor: pointer;
          }

          @include triangle-left;
          .triangle-right {
            border-left: 15px solid #fff;
          }
          .triangle-left {
            border-right: 15px solid #fff;
          }
        }
      }
      .chat-assistant__talent {
        .chat-assistant__talent-text {
          color: #6b757a;
          padding: 0.8rem;
          background-color: #e0ebfa;
          border-radius: 10px;
          margin-right: 1rem;

          @include triangle-right;
          .triangle-right {
            border-left: 15px solid #e0ebfa;
          }
          .triangle-left {
            border-right: 15px solid #e0ebfa;
          }
        }

        .chat-assistant__talent-logo {
          height: 38px;
          min-width: 38px;
          border-radius: 50%;
          background-color: #d9d9d9;

          svg {
            color: #b7bcce;
            font-size: 1.5rem;
          }
        }
      }

      .chat-assistant__talent-bestmatch {
        .chat-assistant__talent-text {
          color: #fff;
          padding: 0.8rem;
          background-color: #7e49ff;
          border-radius: 10px;
          margin-right: 1rem;

          @include triangle-right;
          .triangle-right {
            border-left: 15px solid #7e49ff;
          }
          .triangle-left {
            border-right: 15px solid #7e49ff;
          }
        }

        .chat-assistant__talent-logo {
          height: 38px;
          min-width: 38px;
          border-radius: 50%;

          .chat-assistant__talent-logo-img {
            height: 38px;
            width: 38px;
            border-radius: 50%;
          }
        }
      }

      .chat-assistant__loader {
        background-image: url("../../assets/images/Icon/animation-loader.gif");
        background-size: 100% 100%;
        height: 50px;
        width: 75px;
        // bottom: 15px;
        left: 20px;
        padding-left: 30px;
      }

      .chat-assistant__error {
        width: auto;
        color: #75868f;
        padding-right: 30px;
        font-size: 14px;
        text-align: start;
      }
    }
    .form-control {
      padding: 1.375rem 1.75rem;
    }
  }

  input[type="textarea"] {
    width: 100%;
    height: 100px;
    padding-right: 50px;
  }

  textarea {
    resize: none;
  }

  button.trash {
    background: none;
    position: absolute;
    top: 50%;
    right: 75px;
    background: none;
    border: 0;
    --webkit-appearance: none;
    color: #a4a4a4;
    transition: 0.5s;

    &:hover {
      color: red;
    }
  }

  button[type="submit"] {
    background: none;
    position: absolute;
    top: 50%;
    right: 15px;
    margin-left: -50px;
    height: 25px;
    width: 50px;
    color: #7e49ff;
    border: 0;
    --webkit-appearance: none;
  }
}

@media screen and (max-width: 1200px) {
  .background-imagePost {
    .jobposting-body {
      // gap: 50px;
      display: flex;
      flex-direction: column;
      // background-color: aqua;
      margin: 50px auto;
      padding: 0 25px;

      .sidebar-container {
        width: 100%;
        margin: auto;
      }
    }
  }
}

// For Fix
// .content {
//   display: flex;
//   align-items: center;
//   gap: 50px;
//   padding: 20px 0;

//   .side-bar-menu {
//     align-self: auto;
//     width: fit-content;
//     height: fit-content;
//     margin-left: 18%;
//   }
// }
