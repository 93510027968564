@use "../../../Abstract/" as a;

.faqBody {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .faqTitle {
    font-family: a.$rubik;
    font-size: 40px;
    color: a.$dblue2;
    font-weight: 600;
    text-align: center;
    padding: 30px 0;
  }

  .faqBodyButtonGroup {
    display: flex;
    gap: 20px;
    justify-content: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
}

.faqLink {
  font-family: a.$rubik;
  font-size: 20px;
  font-weight: 400;
  padding: 50px 80px;
  display: flex;
  width: 100%;

  gap: 100px;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    padding: 50px 0px;
    gap: 50px;
  }

  .links {
    min-width: fit-content;
    display: flex;
    flex-direction: column;
    // gap: 15px;

    p.link {
      &:hover,
      &.clicked {
        text-decoration: underline;
        text-decoration-thickness: 3px;
        text-decoration-color: a.$lblue2;
        cursor: pointer;
        text-underline-offset: 8px;
      }
    }
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
  }
}

.messageContainer {
  width: 100%;

  .brandLink {
    font-weight: bold;
  }

  .brandUndelineLink {
    text-decoration: underline;
  }
}
