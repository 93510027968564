@mixin fix-style {
  font-family: "Rubik";
  background-color: #fff;
}

@mixin h5-style {
  color: #8046fa;
  font-size: 3rem;
  font-weight: 600;
}

@mixin p-style {
  font-size: 1.4rem;
  font-weight: 400;
}

.hero {
  font-family: "Rubik";
  background-image: url("../images/LandingPage/TrackingSystem/ats-hero.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 650px;
  margin-top: 80px;

  h5 {
    @include h5-style();
  }

  button {
    background-color: #02b5df;
    border-radius: 30px;
    border: 0;
  }

  p {
    font-size: 1.4rem;
    font-weight: 400;
  }

  .image-one__working {
    height: 390px;
    width: 555px;
    top: 30px;
    left: 660px;

    @media (max-width: 1440px) {
      height: 292.5px;
      width: 416.25px;
      top: 80px;
      left: 490px;
    }
  }

  .image-two__working {
    height: 581px;
    width: 503px;
    top: -140px;
    left: 1160px;
    @media (max-width: 1440px) {
      height: 435.75px;
      width: 377.25px;
      top: -46px;
      left: 867px;
    }
  }
}

.sticker {
  font-family: "Rubik";
  background-color: #8146fa;
  height: 100px;
  width: 100%;
  color: #fff;
  font-size: 2rem;
  font-weight: 400;
}

.unlock-world {
  @include fix-style();
  h5 {
    color: #8046fa;
    font-size: 3rem;
    font-weight: 600;
  }

  p {
    font-size: 24px;
  }

  .unlock-world__image-list {
    p {
      font-size: 24px;
      font-weight: 700;
    }
  }
}

.manage-job {
  @include fix-style();

  h5 {
    @include h5-style();
  }

  p {
    @include p-style();
  }

  h3 {
    font-size: 32px;
    font-weight: 700;
    color: #8046fa;
  }

  .image-list {
    p {
      font-size: 32px;
      font-weight: 400;
      line-height: 1;
    }
  }
}

.sourcing-selection {
  background-color: #8146fa;
  h5 {
    color: #fff;
    font-size: 48px;
    font-weight: 700;
  }

  p {
    color: #fff;
    font-size: 24px;
  }

  h4 {
    color: #fcb017;
    font-size: 28px;
  }

  button {
    border-radius: 30px;
    background-color: #ff4df8;
    color: #fff;
    border: 0;
  }
}

.empowering-subs {
  @include fix-style();
  h5 {
    @include h5-style();
  }
  .empowering-subs__box {
    border: 2px solid #0132dc;
    border-radius: 29px;
    height: 150px;
    width: 100%;
    p {
      font-size: 28px;
      line-height: 1;
      color: #0132dc;
      font-weight: 700;
    }
  }

  .box-rate {
    .box-rate__number {
      font-size: 28px;
    }
    .box-rate__star {
      color: #ffc700;
      font-size: 28px;
    }
  }
}
