.key-skills-talent {
  // width: 100%;
  // height: 100%;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #00000040;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;
  margin: 0 0 20px 0;

  h6 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #003763;
  }

  label {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #003763;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    // background-color: aqua;

    h6 {
      align-items: center;
      margin: auto 0;
    }

    button {
      align-items: center;
      margin: auto 0;
      background: #003763;
      border-radius: 3px;
      border: none;
    }
  }
  .body-container {
    h5 {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #003763;
    }
    .level-container {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;

      .level-content {
        p {
          font-family: "Lato", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #ffffff;
          padding: 5px 10px;
          background: #007bb6;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          border-radius: 20px;

          word-break: break-all;
          white-space: pre-line;

          .icon {
            font-family: "FontAwesome 5";
            font-size: 14px;
            margin: auto 2px auto 10px;
            cursor: pointer;
          }
        }
      }

      .level-content__beginner {
        p {
          background-color: #1d9ce6;
        }
      }

      .level-content__intermediate {
        p {
          background-color: #007bb6;
        }
      }

      .level-content__advance {
        p {
          background-color: #006797;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .key-skills-talent {
    .header-container {
      display: block;

      button {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}
