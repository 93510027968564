.companyJobPostedList {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .jobPostedList {
    .jobPostedListcontent {
      display: flex;
      gap: 10px;
      padding: 5px 0;

      .jobPostedListImgContainer {
        width: 39px;
        height: 39px;
        background: #606060;
        border-radius: 50%;

        img {
          width: 39px;
          height: 39px;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      h1,
      p {
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
        color: #606060;
        margin: 0;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .companyList {
    .companyListInner {
      .companyListcontent {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 7.5px 0;
        .companyListImgContainer {
          width: 39px;
          height: 39px;
          background: #d9d9d9;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            color: #aeaeae;
          }

          img {
            width: 39px;
            height: 39px;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        h1 {
          font-family: "Montserrat", sans-serif;
          font-size: 20px;
          color: #606060;
          margin: 0;
        }
      }
    }
  }
}
