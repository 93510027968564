@import "../scss/Abstract/color";

.not-found {
  background-image: url("../../assets/images/Background/Global/adminLogin.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 250px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 20px;
    font-weight: 300;
    color: #efbb20;
    text-transform: uppercase;
    margin-top: 5rem;
  }
  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 5rem;
    margin-bottom: 0.5rem;
    color: $violet !important;
    font-weight: 700;
    text-transform: uppercase;
  }

  h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    padding: 20px 50px;
    text-align: center;
    color: $violet !important;
    font-weight: 700;
    text-transform: uppercase;
  }

  .link {
    padding: 0 50px;
    cursor: pointer;
    text-decoration: underline;
  }

  p {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 4rem;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
  }
  h4 {
    font-size: 14px;
    color: white;
    text-align: center;
    font-weight: 300;
    margin-top: 2rem;
  }
  button {
    width: 100%;
    height: 50px;
    border: none;
    background-color: #efbb20;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    margin-top: 10px;
    color: #fff;
    font-weight: 500;
    text-decoration: none;
    margin-bottom: 30px;
    padding: 10px;
  }

  .btnContainer {
    margin: 20px 0;

    button {
      width: 100%;
      height: 50px;
      border: none;
      background-color: #efbb20;
      color: white;
      border-radius: 5px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      margin-top: 10px;
      color: #fff;
      font-weight: 500;
      text-decoration: none;
      margin-bottom: 30px;
      padding: 10px;
    }
  }
}
// .logo {
//   // margin-bottom: 7rem;
// }
.btn-label {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}
.btn-label:hover {
  color: #fff;
}

.under__maintenance {
  background-image: url("../images/Background/notFoundBg.png");
  background-size: 100% 100%;
  background-size: cover;
  height: 100vh;
  font-family: Poppins;

  img {
    height: 100px;
    margin-bottom: 2rem;
  }

  h2 {
    color: #232323;
    font-size: 50px;
    font-weight: normal;
    margin-bottom: 10rem;
  }

  button {
    color: #fff;
    background-color: #7849f1;
  }
}
