@use "../../Abstract/" as a;

.customContactNumber {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 999;
  .react-tel-input .form-control {
    width: 100%;
    height: max-content;
    padding: 10px 50px;
  }

  label {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 600;
  }
}

.no-margin {
  margin: 10px 0;
}
