.landing-sentence {
  background-color: #ffff;
  padding: 50px 0;

  .landing-body {
    display: flex;
    justify-content: center;
    h1 {
      text-align: center;
      font-size: 40px;
      color: #ff40b5;
      width: 66.66666667%;
      // word-break: break-all;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}
