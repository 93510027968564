.superAdmin {
  display: flex;

  .navbarContent {
    width: 100%;

    .pageContainer {
      height: calc(100vh - 11vh); /* Set the height as per your requirement */
      overflow-y: auto;

      /* Hide the scrollbar for webkit-based browsers */
      ::-webkit-scrollbar {
        width: 0; /* Adjust the width of the scrollbar as needed */
      }

      ::-webkit-scrollbar-track {
        background: transparent; /* Make the scrollbar track transparent */
      }

      ::-webkit-scrollbar-thumb {
        background: transparent; /* Make the scrollbar thumb (handle) transparent */
      }
    }
  }
}
