.modal-container {
  .modal-header-container {
    padding: 15px 30px;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    color: #000000;

    background: #f5f5f5;
  }

  .modal-body-container {
    padding: 15px 30px;
    background: #fcfcfc;

    label {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #6f6f6f;
    }

    input[type="text"],
    input[type="date"],
    select {
      border: 1px solid #e1e6eb;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
    }

    .cv-label {
      margin-left: 1px;
      margin-top: 5px;
    }
  }

  .modal-footer-container {
    background: #f5f5f5;
    padding: 15px 30px;

    .cancel {
      padding: 7px 20px;
      background: #003763;
      border-radius: 3px;
      border: none;
    }

    .save {
      padding: 7px 20px;
      background: #efbb20;
      border: none;
      border-radius: 3px;
    }
  }
}
