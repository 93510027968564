.about-content {
  background-color: #ffffff;
  padding: 30px;
  h1 {
    color: #003763;
    font-family: "Rubik", sans-serif;
    font-size: calc(1.3rem + 0.6vw);
    margin: 10px 0;
    font-weight: bold;
  }

  p {
    margin-top: 30px;
    margin-bottom: 0px;
    font-family: "Rubik", sans-serif;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      text-decoration: dashed;
    }
  }

  ul li:before {
    content: "-";
    margin-right: 5px;
  }

  span {
    font-weight: bold;
  }
}
