.documents-certificate-talent {
  width: 100%;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #00000040;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;
  margin: 0 0 20px 0;

  h6 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #003763;
  }

  label {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #003763;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    // background-color: aqua;

    h6 {
      align-items: center;
      margin: auto 0;
    }

    button {
      align-items: center;
      margin: auto 0;
      background: #003763;
      border-radius: 3px;
      border: none;
    }
  }

  .cv-list-outer {
    max-height: 300px;
    overflow-y: scroll;
    margin-bottom: 0;

    .cv-list {
      margin-top: 0;
      margin-bottom: 20px;

      p {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        margin-top: 2px;
      }

      label {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #333333;
        line-height: 10px;
      }

      .remove-icon-container {
        display: flex;
        gap: 10px;

        .remove-work-container {
          cursor: pointer;
        }
        margin-top: -5px;
        .remove-work {
          width: 10px;
          height: 11px;
          color: #333333;
        }

        span {
          font-family: "Lato", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          color: #333333;
        }

        button {
          font-family: "Lato", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          color: #ffffff;
          background: #efbb20;
          border-radius: 3px;
          border: none;
        }
      }
    }
  }

  label {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #333333;
    line-height: 10px;
    margin-bottom: 15px;
  }

  .remove-icon-container {
    display: flex;
    gap: 10px;

    .remove-work-container {
      cursor: pointer;
    }
    margin-top: -5px;
    .remove-work {
      width: 10px;
      height: 11px;
      color: #333333;
    }

    span {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #333333;
    }

    button {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      color: #ffffff;
      background: #efbb20;
      border-radius: 3px;
      border: none;
    }
  }
}

@media screen and (max-width: 900px) {
  .documents-certificate-talent {
    .header-container {
      display: block;

      button {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}
