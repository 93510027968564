.landing-banner {
  background-image: url("../../images/LandingPage/banner.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  padding: 50px 0;
  //   height: 350px;

  .landing-body {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 0;

    @media (max-width: 768px) {
      text-align: center;
    }
    .body-text {
      h1 {
        color: #ffffff;
        font-size: 50px;
        width: 65%;
        font-family: "Rubik", sans-serif;
        font-weight: bold;
        @media (max-width: 768px) {
          width: 100%;
        }
      }

      p {
        font-size: 30px;
        font-family: "Rubik", sans-serif;
        font-weight: 500;
        color: #ffffff;
      }
    }

    .button-container {
      display: flex;
      gap: 20px;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      button {
        background-color: #01b5e0;
        border: none;
        border-radius: 30px;
        color: #ffffff;
        font-size: 18px;
        width: 200px;
        padding: 10px 20px;

        @media (max-width: 768px) {
          width: 100%;
          color: #8046fa;
          background-color: #ffffff;
        }
      }

      button:hover {
        background-color: #02cbfb;
      }
    }
  }
}
