.input_search {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0 40px 10px;

  input {
    border: solid 1px #cacaca;
    border-radius: 10px;
    padding: 8px;
  }

  .input_search_magnifying {
    margin-left: -32px;
    cursor: pointer;
  }
}
