.create-job__form {
  .customInput label {
    color: #000;
  }

  input,
  select,
  #jobOverview,
  #jobDescription,
  #qualification,
  #benefits,
  #skillSet,
  #dialect {
    box-shadow: 0px 2px 4px 0px #00000040;
    background-color: #f6f6f9 !important;
  }

  .btn-generate__jobdetail {
    padding: 10px 20px;
    background: #6f64f8;
    border: 0;

    &:hover {
      background: #6f64f8;
    }
  }

  .ai-chatbot-tooltip_mod {
    color: #6f64f8;
    .tooltip-circle {
      width: 20px;
      height: 20px;
      border: solid;
      border-radius: 10px;
      border-width: 2px;
      position: relative;
      top: 10px;

      .tooltip-inner {
        background-color: #6f64f8 !important;
      }
      .info-png {
        width: 12px;
        height: 11.5px;
        position: relative;
        bottom: 6px;
        left: 2px;
      }
    }
  }

  .create-job__btn_yellow {
    background: #efbb20;
    color: #fff;
    border: 1px solid #efbb20;
  }

  .create-job__btn_red {
    background: #e80000;
    color: #fff;
    border: 1px solid #e80000;
  }
  .create-job__btn_blue {
    background: #1d9ce6;
    color: #fff;
    border: 1px solid #1d9ce6;
  }

  .form-check-input {
    background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23666%27/></svg>") !important;
  }
  .form-check-input:checked {
    background-color: #666 !important;
    border-color: #6668 !important;
    background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23fff%27/></svg>") !important;
  }

  .form-check-input:focus {
    border-color: #6668 !important;
    outline: 0;
    box-shadow: none !important;
  }
}
