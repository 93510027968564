@use "../../../Abstract/" as a;

.work_history {
  font-family: a.$poppins;

  .work_history_content {
    font-size: 16px;
    margin: 15px 0;

    h5 {
      margin: 0;
      font-weight: 600;
      font-size: 16px;
    }

    p {
      margin: 0;
      font-weight: 500;
    }
  }
}
