.job-list-client {
  width: 100%;
  // padding: 30px;

  h6 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #003763;
  }

  .job-details-container {
    display: flex;
    // flex-direction: column;
    background: #f9faff;
    border-left: 4px solid #dfe7ff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      // text-align: center;
    }

    .img-container {
      width: 59.66px;
      height: 60px;
      background: #d9d9d9;
      border-radius: 7px;
      margin-right: 10px;
      display: flex;
      overflow: hidden;

      @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
        justify-content: center;
        margin-bottom: 15px;
      }

      img {
        width: 100%;
        height: 100%;
        background: #d9d9d9;
        border-radius: 7px;
        margin-right: 10px;
        object-fit: cover;
        object-position: center;

        @media screen and (max-width: 768px) {
          width: 100%;
          height: 200px;
          margin-right: 0px;
          padding: 10px;
          object-fit: contain;
        }
      }

      .icon {
        margin: auto;
        color: #aeaeae;
        font-size: 30px;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      // @media screen and (max-width: 768px) {
      //   flex-direction: row;
      // }

      h4 {
        // align-items: center;
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        color: #003863;
        margin: 0;
        cursor: pointer;
      }

      .additional-details {
        display: flex;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        h5 {
          margin: 0;
          font-family: "Lato", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          color: #232323;
          width: 200px;
          margin-right: 20px;

          @media screen and (max-width: 768px) {
            width: 100%;
            margin-bottom: 10px;
          }
        }

        .information {
          display: flex;
          gap: 10px;
          //   background-color: #c2c2c2;
          margin: auto 0;

          @media screen and (max-width: 768px) {
            flex-direction: column;
          }

          .jobId {
            text-transform: uppercase;
            margin-right: 5px;
            margin: auto 0;
            font-family: "Lato", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #003863;
          }

          .information-group {
            display: flex;
            align-items: center;

            // @media screen and (max-width: 768px) {
            //   // flex-direction: column;
            //   // justify-content: center;
            // }

            gap: 5px;
            .icon {
              font-size: 14px;
              color: #003863;
            }

            h6 {
              margin: auto 0;
              font-family: "Lato", sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              color: #003863;
            }
          }
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: right;
    align-items: center;

    h6 {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #6f6f6f;
    }

    button {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #666666;

      background: #ffffff;
      border: 1px solid #ebebeb;
      border-radius: 3px;
    }
  }
}
