.customCardWithIcon {
  display: flex;
  gap: 10px;

  @media screen and (max-width: 772px) {
    flex-direction: column;
  }

  .cardWithIcon {
    width: 100%;
    background-color: #fff;
    padding: 20px 20px;
    border-radius: 5px;

    .cardIconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 62px;
      height: 62px;
      border-radius: 50%;

      .icon {
        font-size: 30px;
      }
    }

    .totalJobPosted {
      background-color: #e6f7ee;
      color: #2dcd7a;
    }

    .talentRegistered {
      background-color: #d5d1fb;
      color: #7e72f2;
    }

    .companyRegistered {
      background-color: #ffdfc2;
      color: #ff9f43;
    }

    .totalRegistered {
      background-color: #ffc3c3;
      color: #c70000;
    }

    .totalJobPosted {
      background-color: #e6f7ee;
      color: #2dcd7a;
    }

    .cardTextContainer {
      padding: 10px 10px 0px 10px;

      h1 {
        font-family: "Poppins", sans-serif;
        font-size: 24px;
        font-weight: 600;
        margin: 0;
      }

      p {
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        font-weight: 400;
        margin: 0;
      }
    }
  }
}
