$white: #fff;
$black: #000;
$violet: #6f64f8;
$violet2: #8046fa;
$violet3: #7352e9;
$lblue: #e1e7ff;
$lblue2: #01b5e0;
$lblue3: #02cbfb;
$lblue4: #3085d6;
$blue: #1d9ce6;
$blue2: #007bb6;
$dblue: #003763;
$dblue2: #203e78;
$dblue3: #003863;
$yellow: #efbb20;
$yellow2: #ffb601;
$gray: #666666;
$gray2: #6f6f6f;
$gray3: #f5f5f5;
$gray4: #f6f6f9;
$green: #0e932b;
$pink: #ff3eb5;
$pink2: #eb51b1;
$red2: #e80000;
$red: #d40000;
