@use "../../../Abstract/" as a;

.formModal {
  .modal-header {
    border: none;
    font-family: a.$lato;
    color: a.$dblue;
  }

  .modal-content {
    background-color: #fff;

    .btnContainer {
      width: 100%;
      display: flex;
      margin-top: 30px;
      gap: 10px;
      width: 100%;
      button {
        flex: 1;
        border: none;
      }

      .cancelBtn {
        background-color: rgb(172, 172, 172);
      }

      .yellowBtn {
        background-color: a.$yellow;
      }
    }
  }
  .modal-footer {
    border: none;
    width: 100%;
    display: flex;
    button {
      flex: 1;
      border: none;
    }

    .cancelBtn {
      background-color: rgb(172, 172, 172);
    }

    .yellowBtn {
      background-color: a.$yellow;
    }
  }
}
