.CustomCheckbox {
  margin: 10px 0;
  .checkbox {
    margin-right: 5px;
    mix-blend-mode: multiply;
  }

  a {
    margin-left: 5px;
    font-weight: 400;
    color: #3085d6;
  }

  .noUnderline {
    text-decoration: none;
  }
}

input[type="checkbox"].error-border {
  // -moz-appearance: none;
  // -webkit-appearance: none;
  // -o-appearance: none;
  // border: none !important ;
  outline: 1px auto #d40000 !important;
}
