@import "../../layout/message";

.edit-save-draft-modal {
  label {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }
  .edit-save-title {
    background: #f5f5f5;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    color: #000000;
  }

  .manage-job-modal-body {
    background: #fcfcfc;
    padding: 20px 25px;

    h6 {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      color: #003763;
    }

    .hiring-manager-info-container {
      input {
        border: 1px solid #e1e6eb;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }

      .hiring-manager-info-fields {
        display: flex;
        gap: 20px;

        .fullname-group,
        .email-group {
          flex: 1 1 0;
        }

        .contact-number-group {
          #hiringContact {
            padding: 6px;
          }
          .error-msg {
            margin-top: 4px;
          }
        }
      }
    }

    .job-information-container {
      .job-title-type-container {
        display: flex;
        gap: 20px;

        input {
          border: 1px solid #e1e6eb;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
        }

        div {
          flex: 1 1 0;
        }
      }

      .work-arrangement-office-hours-container {
        display: flex;
        gap: 20px;

        select {
          border: 1px solid #e1e6eb;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
        }

        .work-arrangement-group,
        .office-hours-group {
          flex: 1 1 0;
        }

        .start-end-time-container {
          display: flex;
          gap: 20px;

          input {
            border: 1px solid #e1e6eb;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
          }

          div {
            flex: 1 1 0;
          }
        }
      }

      .address-city-container {
        display: flex;
        gap: 20px;

        input {
          border: 1px solid #e1e6eb;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
        }

        .office-address-group {
          flex: 2 1 0;
        }

        .city-group {
          select {
            border: 1px solid #e1e6eb;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
          }

          flex: 1 1 0;
        }
      }

      .salary-group {
        width: 100%;

        input {
          border: 1px solid #e1e6eb;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
        }

        .max-min-salary {
          display: flex;
          width: 100%;
          gap: 20px;

          div {
            width: 100%;
          }
          .salary-code-input-container {
            display: flex;
            align-items: center;
            position: relative;
          }

          .php-code {
            position: absolute;
            left: 8px;
          }

          #minSalary,
          #maxSalary {
            padding: 7px 20px;
            border: solid 1px #ccc;
            border-radius: 5px;
          }
        }
      }

      .benefits-group {
        .css-13cymwt-control {
          border: 1px solid #e1e6eb;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
        }
      }

      .quill {
        // border: 1px solid #e1e6eb;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;

        .ql-toolbar {
          border: 1px solid #e1e6eb;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          box-shadow: 0px 2px 4px -4px rgba(0, 0, 0, 0.25);
        }

        .ql-container {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          border: 1px solid #e1e6eb;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 2px 4px -4px rgba(0, 0, 0, 0.25);
        }
      }

      .job-overview-group,
      .job-description,
      .qualification-group {
        textarea {
          border: 1px solid #e1e6eb;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
        }

        // .charactersLength {
        //   font-size: 14px;
        //   color: gray;
        //   // background-color: #003763;
        //   margin-top: 10px;
        //   text-align: right;
        // }
      }
    }

    .job-requirements-container {
      .skill-set-dialect-fields {
        display: flex;
        gap: 20px;

        .skill-set-group,
        .dialect-group {
          flex: 1 1 0;
        }
      }

      .css-13cymwt-control {
        border: 1px solid #e1e6eb;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }
    }

    .start-end-date-fields {
      display: flex;
      gap: 20px;

      div {
        flex: 1 1 0;
      }

      input {
        border: 1px solid #e1e6eb;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }
    }
  }
  .actions-button-container {
    background: #f5f5f5;
    .save-draft-btn {
      background: #efbb20;
      border-radius: 3px;
      border: none;

      .btn-icon {
        margin-right: 10px;
      }
    }

    .publish-btn {
      background: #003763;
      border-radius: 3px;
      border: none;

      .btn-icon {
        margin-right: 10px;
      }
    }
  }
}

// .label-subText-library {
//   width: 100%;
//   margin-top: 0.25rem;
//   font-size: 0.875em;
//   color: #f64e60;
//   font-weight: 600;
//   text-align: left;
// }
