@use "../../../Abstract/" as a;

.basic_information {
  font-family: a.$poppins;

  .basic_information_property {
    display: flex;
    align-items: center;
    margin: 8px 0;

    h5 {
      margin: auto 0;
      min-width: 170px;
      font-weight: 600;
      font-size: 16px;
    }

    p {
      margin: auto 0;
      word-break: break-all;
      white-space: pre-line;
      font-weight: 400;
      font-size: 16px;
    }
  }
}
