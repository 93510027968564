@use "../../Abstract/" as a;

.containerIndex {
  min-height: 100vh;

  // max-height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // background-color: aqua;
  padding: 50px 0;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }

  .content {
    width: 400px;
    background-color: a.$white;
    padding: 30px;
    box-shadow: 0px 2px 8px 0px #00000040;
    border-radius: 20px;

    .loginTitle {
      h1,
      p {
        text-align: center;
        font-size: 25px;
        font-weight: 700;
        color: a.$dblue;
      }

      p {
        margin: 10px 0 20px;
        font-size: 16px;
        font-weight: 400;
      }
    }

    .loginTitleLeft {
      margin-bottom: 20px;

      .icon {
        cursor: pointer;
      }

      h1 {
        text-align: left;
        font-size: 25px;
        font-weight: 700;
        color: a.$dblue;
        margin: auto 0;
        //
      }

      p {
        margin: 10px 0 0;
        font-size: 16px;
        font-weight: 400;

        span {
          word-break: break-all;
          white-space: pre-line;
          font-weight: 600;
        }
      }
    }

    .loginTitleLeftWithIcon {
      display: flex;
      gap: 10px;
      align-items: center;
      // background: gray;
      margin-bottom: 20px;
      // .icon {
      //   align-items: center;
      // }

      .icon {
        cursor: pointer;
      }

      h1 {
        text-align: left;
        font-size: 25px;
        font-weight: 700;
        color: a.$dblue;
        margin: auto 0;
        //
      }

      p {
        margin: 10px 0 0;
        font-size: 16px;
        font-weight: 400;

        span {
          font-weight: 600;
          word-break: break-all;
          white-space: pre-line;
        }
      }
    }

    .buttonContCenter {
      margin: 30px;
      display: flex;
      justify-content: center;
    }
  }
}
