.applicant-list-container {
  // width: 100%;
  // height: 100%;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #00000040;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;
  margin: 0 0 20px 0;

  h6 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #003763;
  }

  p {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
  }

  label {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #3085d6;
  }

  .show-search {
    display: flex;
    justify-content: space-between;

    .show-group {
      display: flex;
      gap: 5px;

      p {
        margin: auto 0;
      }
    }

    .search-group {
      display: flex;
      gap: 5px;

      p {
        margin: auto 0;
      }
    }
  }

  //pending

  .applicant-container-inner {
    // width: 100%;
    .applicant-table {
      thead {
        tr {
          // width: 100%;
          background: #e1e7ff;

          th {
            font-family: "Lato", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #000000;
            padding: 10px;
            // min-width: 100px;
          }
        }
      }

      tbody {
        td {
          padding: 10px;

          // min-width: 200px;
        }

        .view-btn {
          background: #3085d6;
          border-radius: 3px;
          border: none;
        }
      }
    }
  }

  .reset-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .update-btn {
      background: #efbb20;
      font-size: 16px;
      font-family: "Lato", sans-serif;
      font-weight: 500;
      border: none;
    }

    .update-btn:hover {
      background: #efbb20;
    }
  }
  .entries-pagiation-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // background-color: #007bb6;

    .entries-label {
      display: flex;
      align-items: center;
      font-family: "Lato", sans-serif;
      font-weight: 500;
      font-size: 16px;
      color: #6f6f6f;
    }

    button {
      background: #efbb20;
      border: none;
    }
  }
}
