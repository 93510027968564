.side-bar {
  // line-height: 19px;
  width: 268px;
  margin: 0;
  padding: 0;
  border: 1px solid #00000040;
  border-width: 1px 0px 0px 0px;
  box-shadow: 0px 2px 4px 0px #00000040;

  .list-group {
    // width: 100%;

    a {
      cursor: pointer;
      border-radius: 0px;
      color: #003763;
      font-family: "Lato", sans-serif;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.3s;
      .sidebar-icon {
        margin-right: 10px;
      }
    }
    a:hover {
      background-color: #f0f0f0;
    }
  }

  a.list-group-item-action.list-group-item:hover {
    border-left: 4px solid #01b5e0;
  }
}

.list-group-item.active {
  background-color: #e1e7ff;
}

@media screen and (max-width: 768px) {
  .side-bar {
    width: 100%;
    .list-group {
      width: 100%;
    }
  }
}

@media screen and (max-width: 992px) {
  .side-bar {
    width: 100%;
    .list-group {
      width: 100%;
    }
  }
}

a.list-group-item-action.list-group-item {
  // background: white;
  border: 1px solid #00000040;
  border-width: 0px 1px 1px 1px;
}

a.list-group-item-action {
  background: white;
  border: 1px solid #00000040;
}
