.keyskill_container {
  max-height: 400px;
  overflow-y: auto;

  .select_group {
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;

    label {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #003763;
      word-break: break-all;
      white-space: pre-line;
      margin-bottom: 10px;
    }

    .keyskills_list {
      gap: 10px;
      display: flex;
      flex-wrap: wrap;

      .keyskills_container {
        color: #ffff;
        background: #0dcaf0;
        text-align: center;
        border-radius: 20px;
        display: flex;
        padding: 10px 20px;
        min-width: fit-content;
        word-break: break-all;
        white-space: pre-line;

        p {
          margin: auto;
          font-weight: 600;
          font-size: 14px;
          text-align: left;
        }
      }
    }
  }
}
