.prod-home__body {
  .prod-home__card {
    img {
      width: 100%;
    }

    h3 {
      color: #0132dc;
      font-weight: 700;
      font-family: "Rubik";
    }

    p {
      font-weight: 400;
      font-family: "Inter", sans-serif;
      font-size: clamp(1rem, 6.5vw, 1.5rem);
    }
  }
}

.prod-home__subs {
  background-image: url(../../images/bg-subs.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 750px;
  width: 100%;

  @media (min-width: 1921px) {
    background-size: cover;
  }

  @media (max-width: 1440px) {
    height: 600px;
  }

  @media (max-width: 600px) {
    height: 200px;
  }

  p {
    font-size: clamp(1rem, 6.5vw, 2.3rem);
    color: #000;

    span {
      color: #ec52b2;
    }
  }

  h5 {
    font-size: clamp(0.7rem, 6.5vw, 1.5rem);
  }

  .prod-home__link {
    text-decoration: none;
    color: #0132dc;
    font-weight: 700;
  }
  .btn-subscribe {
    border-radius: 20px;
    border: 0;
    background-color: #ff4df8;
  }
}

.subs-container {
  height: 75px;
  background-color: #4cc6f3;

  .btn-subscribe {
    border-radius: 20px;
    border: 0;
    background-color: #ff4df8;
  }
}
