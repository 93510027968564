.view_resume_history {
  .view_resume_history_content {
    max-height: 330px;
    overflow-y: auto;

    h5 {
      margin: 0;
      font-weight: 600;
      font-size: 16px;
    }

    p {
      margin: 0;
      font-weight: 500;
    }
  }
}
