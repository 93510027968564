@use "../../Abstract/" as a;

.socialMediaIcon {
  margin: 10px 0;
  display: flex;
  gap: 10px;

  .iconContainer {
    cursor: pointer;
    background-color: a.$white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      font-size: 25px;
      color: a.$violet2;

      @media screen and (max-width: 768px) {
        color: a.$lblue2;
      }
    }
  }
}
