.subsciption-container {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 30px;
  font-family: Montserrat;

  .text-free_trial {
    color: #01b5e0;
  }

  label {
    font-size: 12px;
    color: #57606a;
    font-weight: 500;
  }

  ul {
    margin: 6px 0;
    li {
      font-size: 13px;
      color: #57606a;
      font-weight: 500;
    }
  }

  h6 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #003763;
  }

  h5 {
    color: #57606a;
    -webkit-transform: scale(1.3, 1.2); /* Safari and Chrome */
    -moz-transform: scale(1.3, 1.2); /* Firefox */
    -ms-transform: scale(1.3, 1.2); /* IE 9 */
    -o-transform: scale(1.3, 1.2); /* Opera */
    transform: scale(1.3, 1.2); /* W3C */
  }

  h4 {
    color: #01b5e0;
    font-size: 24px;
    font-weight: 700;
    font-family: Montserrat;
  }

  .add-ons {
    width: 100%;
    height: 13px;
    border-bottom: 1px solid #606060;
    text-align: left;
    font-weight: 600;

    span {
      font-size: 16px;
      background-color: #fff;
      padding: 0 10px;
    }
  }

  .card__wrap--subscription__container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .recommended-border {
    border: 5px solid #8c64d2;
  }

  .recommended--details {
    position: relative;
    padding: 10px 0;
    background-color: #8c64d2;
    h1 {
      text-align: center;
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      color: #fff;
    }

    img {
      width: 200px;
      @media (max-width: 600px) {
        width: 150px;
      }
    }

    // img {
    //   position: absolute;
    //   width: 60px;
    //   height: 55px;
    //   top: 5px;
    //   right: 10px;
    //   transform: translate(50%, -50%);
    // }
  }

  .subscription-content {
    display: flex;
    width: 100%;
    gap: 20px;

    div {
      width: 100%;
      label {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #000000;
      }
    }
  }
  .subscription-sentence {
    font-weight: 500;
    text {
      color: #e80000;
    }
  }

  .card-free {
    box-shadow: inset 0px 8px 0px 15px #8c64d2;
    border-radius: 2rem;
  }
  .btn-green {
    padding: 9px;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    line-height: 17px;
    background: linear-gradient(0deg, #0e932b, #0e932b),
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(255, 255, 255, 0) 100%
      );

    &:hover {
      color: #fff;
    }
    &:active {
      color: #fff;
      background-color: #fff;
    }
    &:focus {
      color: #fff;
    }

    &:disabled {
      background: #d9d9d9;
      color: #fff;
    }
  }

  .btn-blue {
    padding: 9px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 17px;
    background: linear-gradient(0deg, #34a6e9, #34a6e9),
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(255, 255, 255, 0) 100%
      );

    &:hover {
      color: #fff;
    }
    &:active {
      color: #fff;
    }
    &:focus {
      color: #fff;
    }
    &:disabled {
      background: #34a6e9;
      color: #fff;
    }
  }

  .btn-red {
    padding: 8px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    background: linear-gradient(0deg, #e80000, #e80000),
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(255, 255, 255, 0) 100%
      );

    &:hover {
      color: #fff;
    }
    &:active {
      color: #fff;
    }
    &:focus {
      color: #fff;
    }
    &:disabled {
      background: #e80000;
      color: #fff;
    }
  }

  .card-shadow {
    box-shadow: 0px 12px 28px 0px #8c959f4d !important;
  }

  .container--subs__card {
    position: relative;
    margin-top: 20px;
    width: 275px;
    min-height: 540px;
    border-radius: 10px;

    .container--subs__card--plan {
      font-size: 24px;
      font-weight: 700;
      color: #232323;
    }

    .container--subs__card--amount {
      text-align: center;
      h5 {
        font-weight: 400;
        font-size: 20px;
        color: #57606a;
      }

      h2 {
        font-weight: 400;
        font-size: 48px;
      }
    }

    .prem__card--details {
      background-color: #f6f8fa;
      padding: 10px 20px;
      width: 100%;
      position: absolute;
      bottom: 0;
    }

    .prem__card--details--recommended {
      height: 48%;
    }

    .prem__card--details--default {
      height: 53%;
    }
  }

  .card__text-violet {
    color: #8046fa;
  }

  .config-subs,
  .order-summary {
    font-family: Montserrat;
    border-radius: 8px;
    box-shadow: 0px 12px 28px 0px #8c959f4d;

    h2 {
      font-size: 24px;
    }
    p {
      margin-bottom: 0.5rem;
    }

    .config-subs--amount {
      display: flex;
      align-items: flex-start;
      position: relative;

      h2 {
        font-size: 48px;

        // margin-left: 10px;

        .config-subs--peso {
          position: absolute;
          font-weight: 400;
          font-size: 20px;
        }

        .config-subs--amount__num {
          margin-left: 25px;
          font-weight: 400;
        }

        .config-subs--month {
          font-weight: 400;
          font-size: 20px;
        }
      }
    }

    .order-summary--title {
      font-weight: 600;
      font-size: 24px;
    }

    .receipt__background {
      color: #57606a;
      font-size: 13px;
      background-color: #f6f8fa;
      border-radius: 0px 0px 8px 8px;

      .receipt--plan__name {
        font-weight: 600;
      }

      .total {
        font-weight: 700;
      }
    }

    .receipt--total__amount {
      h2 {
        font-size: 32px;
        font-weight: 400;
        color: #57606a;
      }
    }

    .receipt--total--text {
      font-size: 12px;
      font-weight: 400;
    }
  }

  .checkout-page {
    border-radius: 8px 8px 0 0;
    box-shadow: 0px 12px 28px 0px #8c959f4d;
  }

  .subs-features {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    color: #57606a;
  }

  input[type="number"] {
    height: 20px;
    border-radius: 5px;
    border: 1px solid #e1e6eb;
    box-shadow: 0px 2px 4px 0px #00000040;
  }

  .subs__history-tbody {
    td {
      background-color: #fff;
    }

    .subs__history-pending {
      background: #efbb20;
    }

    .subs__history-paid {
      background: #0e932b;
    }

    .subs__history-cancelled {
      background: #d10000;
    }

    .subs__history-renew {
      background: #1d9ce6;
    }
  }
}

.stepper {
  height: 75px;
}

.stepper > div > div > div > a,
.stepper > div > div > div:nth-child(2) > div:nth-child(2),
.stepper > div > div > div:nth-child(3) > div:nth-child(2) {
  margin-top: -65px !important;
}

.subscription-container__mod {
  background: transparent;
  border: none;
  box-shadow: none !important;
}

.checkout-page {
  .checkout-premium__border {
    border-radius: 15px;
    border: 1px solid #d9d9d9;
  }
  .checkout-list__mod {
    list-style: none;
    padding-left: 0;
  }
}
.checkout-page__mod {
  border-radius: 8px !important;

  button {
    width: 25rem;
  }
}

.checkout-page__pay {
  padding: 1rem;
  font-size: 18px;
  background-color: #f4f4f4;
}
.checkout-page__subtotal {
  background-color: #eeeeee;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 12px 28px 0px #8c959f4d;

  .checkout-text__violet {
    color: #8046fa !important;
  }
}

.active-subscription {
  h3 {
    font-weight: 700;
    font-size: 24px;
  }
  button {
    background-color: #34a6e9 !important;
    border: 0;
    font-weight: 700;
  }

  p {
    color: #000;
    span {
      color: #e80000 !important;
    }
    font-weight: 600;
    &:nth-child(1) {
      text-decoration: underline;
      color: #000 !important;
    }
    &:nth-child(3) {
      color: #003763 !important;
    }
  }
}

.upgrade__contact-label {
  background-color: #e1e6eb;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;

  .icon {
    margin-right: 5px;
  }

  a {
    text-decoration: none;
    color: #3085d6;

    &:hover {
      text-decoration: underline;
    }
  }
}
.active-subs__modal {
  font-family: "Lato", sans-serif;
  .modal-content {
    background-color: #fff;
    border: 0;
    .modal-title {
      font-size: 18px !important;
      font-weight: 800;
    }
    tr {
      td {
        &:nth-child(1) {
          font-weight: 700;
        }
      }

      .subs-details__list {
        padding-top: 10px;
        font-weight: 500 !important;
      }
    }
  }
}

.active-subs-tab-content {
  gap: 20px;
  flex-wrap: wrap;
}
