.ats__user-management {
  .ats__btn-adduser {
    background-color: #1d9ce6;
    border: 1px solid #1d9ce6;
    font-family: Poppins;
  }

  table {
    tr {
      width: 100%;
      height: 50px;
    }

    td:last-child {
      text-align: right;
    }

    .action-title {
      text-align: end;
      font-weight: 600;
      padding-right: 60px;
    }

    .user-title {
      font-weight: 600;
      padding-left: 30px;
    }

    .user-row {
      div {
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
      }
    }

    .action-buttons {
      div {
        margin-right: 20px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .ats__btn-edituser {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 8px;
    background-color: #3085d6;
    color: #fff;
    font-size: 20px;
  }

  .ats__btn-deleteuser {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 8px;
    background-color: #d10000;
    color: #fff;
    font-size: 20px;
  }

  table tbody td {
    background-color: #fff !important;
    font-size: 0.875em;
  }
}

.add-modal__button {
  p {
    color: #d40000;
  }
}
.modal-content {
  .ats__btn-save {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 8px;
    background-color: #0e932b;
    color: #fff;
  }
}
