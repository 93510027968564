.st-sidebar {
  // line-height: 19px;
  width: 268px;
  margin: 0;
  padding: 20px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
  gap: 20px;

  h5 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #002848;
    padding: 0;
    margin: 0;
  }

  button {
    width: 100%;
    border: none;
    background: #3085d6;
    border-radius: 5px;

    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
  }
}

@media screen and (max-width: 768px) {
  .side-bar {
    width: 100%;
    .list-group {
      width: 100%;
    }
  }
}
