@import "../../../layout/message";

.client-form-signup {
  width: 366px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 20px;

  .form-title-step {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    h1 {
      text-transform: uppercase;
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #013763;
    }

    h2 {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
  }

  .form-input-group {
    input,
    select {
      border: 1px solid #e1e6eb;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
    }

    // .error-msg {
    //   font-family: "Inter", sans-serif;
    //   font-size: 13px;
    //   color: red;
    //   margin-top: 10px;
    //   font-weight: bold;
    // }

    label {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #000000;
      margin-bottom: 12px;

      .required-field {
        color: red;
        margin-left: 3px;
      }

      .optional-field {
        font-size: 13px;
        font-weight: 400;
        margin-left: 5px;
        color: grey;
      }
    }
  }

  // Button
  .one-button-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    button {
      padding: 6px 30px;
      background: #efbb20;
      border-radius: 3px;
      border: none;
    }
  }

  .two-button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    .back-btn {
      min-width: 100px;
      border: none;
      background: #003763;
      border-radius: 3px;
    }

    .next-btn {
      min-width: 100px;
      border: none;
      background: #efbb20;
      border-radius: 3px;
    }
  }

  .privacy-instructions {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #6f6f6f;
    margin-bottom: 30px;
  }

  .privacy-checkbox-container {
    margin: 20px 0 30px;
    label {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
    }
  }
}

//Privacy Policy
// .privacy-policy-container {
//   font-family: "Lato", sans-serif;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 16px;
//   color: #6f6f6f;

//   .privacy-title {
//     font-family: "Lato", sans-serif;
//     font-style: normal;
//     font-weight: 700;
//     font-size: 16px;
//     color: #013763;
//   }
// }

@media (max-width: 992px) {
  .client-form-signup {
    // width: 100%;
    margin: auto;

    // .one-button-container {
    //   button {
    //     max-width: 120px;
    //   }
    // }

    .two-button-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      button {
        width: 100%;
      }
    }
  }
}

@media (max-width: 400px) {
  .client-form-signup {
    width: 100%;
    margin: auto;

    // .one-button-container {
    //   button {
    //     max-width: 120px;
    //   }
    // }

    .two-button-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      button {
        width: 100%;
      }
    }
  }
}
