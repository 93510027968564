@use "../abstract/" as a;

.whats-next__container {
  position: relative;
  font-family: "Rubik";
  color: #fff;
  height: 100vh;

  .bg-container__section-one {
    position: relative;
    display: flex;
    width: 100%;
  }
  .bg-container__section-two {
    position: relative;
    display: flex;
    width: 100%;
  }
  .bg-container__violet-bg {
    background-color: #874fff;
    width: 50%;
    min-height: 100vh;

    // h1 {
    //   font-weight: 700;
    //   font-size: 55px;
    //   text-align: center;
    //   margin-top: 40px;
    // }

    .employer-button {
      display: flex;
      margin: 0 auto;
      background-color: #f4b940;
      color: #0b008b;
      border: none;
      border-radius: 15px;
      padding: 15px 32px;
      font-size: 15px;
      font-weight: 800;
      margin-top: -10px;
      z-index: 10;
      position: relative;
    }
    p {
      font-weight: 700;
      font-size: 25px;
      text-align: center;
      margin-top: 70px;
      line-height: 0.8;
      z-index: 10;
      position: relative;
    }

    span {
      font-weight: 700;
      font-size: 55px;
      text-align: center;
    }

    // h2 {
    //   font-weight: 700;
    //   font-size: 25px;
    //   text-align: center;
    // }
  }
  .bg-container__blue-bg {
    background-color: #34c3ff;
    width: 50%;
    min-height: 100vh;
    // h1 {
    //   font-weight: 700;
    //   font-size: 55px;
    //   text-align: center;
    //   margin-top: 40px;
    // }

    .jobseeker-button {
      display: flex;
      margin: 0 auto;
      background-color: #eb51b1;
      color: #0b008b;
      border: none;
      border-radius: 15px;
      padding: 15px 32px;
      font-size: 15px;
      font-weight: 800;
      margin-top: -10px;
      position: relative;
      z-index: 10;
    }

    p {
      font-weight: 700;
      font-size: 25px;
      text-align: center;
      margin-top: 70px;
      line-height: 0.8;
      z-index: 10;
      position: relative;
    }

    span {
      font-weight: 700;
      font-size: 55px;
      text-align: center;
    }
    // h2 {
    //   font-weight: 700;
    //   font-size: 25px;
    //   text-align: center;
    // }
  }

  .bg-container__img-person {
    width: 100vw;
    position: absolute;
    bottom: 100px;
    z-index: 0;
  }

  .bg-container__section-two {
    // position: relative;

    .bg-container__footer-img {
      width: 100vw;
      // bottom: -139px;
      // position: absolute;
      margin-top: -250px;
      background-image: url("../../../images/WhatsNext/img/LandingPage/FooterImg.png");
      background-size: 100% 100%;

      // @media (max-width: 320px) {
      //   width: 100vw;
      //   height: 32vh;
      // }
    }

    .footer-text-img__container {
      .bg-container__footer-text-img {
        margin: 0 auto;
        position: relative;
        top: 45px;
        width: 44vw;
        height: 20vh;
        background-image: url("../../../images/WhatsNext/img/LandingPage/FooterTextImg.png");
        background-size: 100% 100%;

        @media (max-width: 425px) {
          margin: 0 auto;
          position: relative;
          top: 60px;
          bottom: 5px;
          width: 64vw;
          height: 17vh;
        }
        @media (max-width: 320px) {
          margin: 0 auto;
          position: relative;
          top: 65px;
          width: 65vw;
          height: 15vh;
        }
        // @media (max-width: 425px) {
        //   margin: 57px 66px;
        //   width: 69vw;
        //   height: 17vh;
        // }
        // @media (max-width: 375px) {
        //   margin: 73px 70px;
        //   width: 66vw;
        //   height: 14vh;
        // }

        // @media (max-width: 320px) {
        //   margin: 69px 55px;
        //   width: 66vw;
        //   height: 14vh;
        // }
      }
    }
  }

  .bg-container__img-grad {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
}
