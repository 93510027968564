.superAdminSidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border: #0000002b;
  box-shadow: #0000002b;
  width: 100%;
  padding: 15px;
  height: 100vh;
  gap: 10px;

  @media screen and (max-width: 772px) {
    padding: 0px;
    width: 100%;
  }

  .admin-sidebar__job_text {
    color: #1d9ce6;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;
  }

  .admin-sidebar__back-text {
    color: #6f64f8;
    cursor: pointer;
    font-weight: 500;
  }

  ul li {
    list-style: none;
    cursor: pointer;
  }

  li:hover {
    background-color: #1d9ce6;
    border-radius: 5px;
    color: #fff;
  }

  .ai-chatbot-text {
    color: #6f64f8;
  }

  .act-li {
    background-color: #1d9ce6;
    border-radius: 5px;
    color: #fff;
  }

  .sidebarLinkContainer {
    overflow: auto;
    img {
      width: 168px;
      height: 60px;
      margin-bottom: 25px;

      @media screen and (max-width: 772px) {
        display: none;
      }
    }

    .sidebarSection {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 5px;

      h1 {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        color: #1d9ce6;
        font-size: 20px;
      }
    }

    .sidebarLink {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #232323;
      // margin-left: 10px;
      border-radius: 5px;
      //   background-color: #ececec;

      p {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        margin: 0;
        font-size: 20px;
      }

      .icon {
        margin: 0;
      }
    }
  }

  .tooltip-circle {
    width: 18px;
    height: 18px;
    border: solid;
    border-radius: 10px;
    border-width: 2px;
  }

  .exclamation-png {
    width: 12px;
    height: 11.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1px;
    padding-top: 1px;
  }

  .sidebar-text-container {
    position: relative;
    // top: 35px;
    p {
      font-family: "Rubik";
      a {
        color: #1d9ce6;
      }
    }
  }
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  border-right-color: #fff !important;
  right: -6px !important;
}

.tooltip-inner {
  font-size: 0.6rem;
  background-color: #fff !important;
  color: #000 !important;
  font-style: italic;
  font-family: "Montserrat";
  box-shadow: 5px 10px #888888;
  text-align: left !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 10px !important;
}
