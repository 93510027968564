.scroll__button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #8146fa;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;

  :hover {
    opacity: 0.8;
  }
}
