.st-basic-information-container {
  width: 100%;
  height: 100%;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #00000040;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;
  margin: 0 0 20px 0;

  h6 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #003763;
  }

  p {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #212529;
  }

  .user-information {
    display: flex;
    gap: 20px;

    .label-container {
      display: flex;

      label {
        word-break: break-all;
        white-space: pre-line;
        width: 150px; /* Adjust the width as needed */
        flex-shrink: 0;
        margin-right: 10px; /* Add spacing between the label and content */
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .st-basic-information-container {
    width: 100%;
    height: 100%;
    padding: 30px;
    background: #ffffff;
    border: 1px solid #00000040;
    border-radius: 5px;
    box-shadow: 0px 2px 4px 0px #00000040;
    margin: 0 0 20px 0;

    h6 {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      color: #003763;
    }

    p {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #212529;
    }

    .user-information {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;

      .label-container {
        display: flex;

        label {
          word-break: break-all;
          white-space: pre-line;
          width: 150px;
          flex-shrink: 0;
          margin-right: 10px;
        }
      }
    }
  }
}
