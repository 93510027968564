.landing-footer {
  // background-image: url("../../images/LandingPage/footer.png");
  background-color: #013763;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  padding: 50px 0;
  font-family: "Inter", sans-serif;

  .footer-body {
    display: flex;
    gap: 100px;

    @media (max-width: 1024px) {
      gap: 50px;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 30px;
      text-align: center;
    }

    .logo-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (max-width: 768px) {
        gap: 0px;
        margin: 0;
        align-items: center;
      }
      img {
        width: 200px;

        @media (max-width: 768px) {
          margin-bottom: 20px;
        }
      }
    }

    .social-media-container {
      display: flex;
      gap: 10px;
      margin-top: 20px;

      @media (max-width: 768px) {
        margin-top: 0px;
      }
      img {
        width: 40px;
        cursor: pointer;
      }
      .npc {
        width: 90px;
        margin-left: 30px;
      }
    }

    .links-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 500px;

      @media (max-width: 1024px) {
        width: 400px;
      }
      @media (max-width: 768px) {
        align-items: center;
      }

      .link-list {
        display: flex;
        gap: 10px;
        align-self: flex-start;

        @media (max-width: 768px) {
          justify-content: center;
          align-items: center;
          text-align: center;
          width: 100%;
        }

        ul,
        li {
          list-style: none;
          text-decoration: none;
          color: #fff;
        }

        a {
          font-family: "Rubik", sans-serif;
          color: #ffffff;
          margin: 0;
          text-decoration: none;
          cursor: pointer;
        }

        a:hover {
          color: #00738e;
        }
      }

      .copyright-container {
        display: flex;
        margin-top: 10px;
        p {
          cursor: pointer;
          font-size: 14px;
          color: #ffffff;
          font-family: "Rubik", sans-serif;
        }

        .arr-text {
          font-size: 14px;
          color: #ffffff;
          font-family: "Rubik", sans-serif;
        }

        p:hover {
          color: #00738e;
        }
        .arr-text:not(:last-child)::after,
        p:not(:last-child)::after {
          // content: " | ";
          margin-right: 5px;
        }
      }
    }
  }
}

.all-reserved {
  background-color: #01b5e0;
  width: 100%;
  text-align: center;
  color: #fff;
}
