@import "./layout/message";
@media screen and (min-width: 992px) {
  .update-profile-container {
    max-width: 100%;
    height: 100%;
    padding: 30px;
    background: #ffffff;
    border: 1px solid #00000040;
    border-radius: 5px;
    box-shadow: 0px 2px 4px 0px #00000040;

    input,
    select {
      border: 1px solid #e1e6eb;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
    }

    .company-profile {
      h1 {
        color: #003763;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 800;
        font-family: "Lato", sans-serif;
      }

      .company-details {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        .company-input-lg-width {
          // flex-basis: 1 1 0;
          width: calc(100% / 1.5 - 5px);

          #industry {
            width: 60%;
            border: 0.5px solid #e1e6eb;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
          }
        }

        .company-input-sm-width {
          width: calc(100% / 3.1 - 3px);
        }

        .ph-input {
          display: flex;
          gap: 20px;

          // #regionSelect {
          width: 100%;
          // }

          .ph-input-container {
            width: calc(100% / 3);

            select {
              width: 100%;
            }
          }
        }
      }
    }

    .contact-person-container {
      margin-top: 50px;
      h1 {
        color: #003763;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 800;
        font-family: "Lato", sans-serif;
      }

      .profile-fullname {
        display: flex;
        justify-content: space-between;
        //   padding: 0;
        //   gap: 5px;
        .company-input-name {
          width: calc(100% / 3 - 15px);
        }
      }

      .contact-details {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .company-input-contact {
          width: calc(100% / 2);
        }

        #contactNumber {
          border: 1px solid #ced4da;
          border-radius: 0.3rem;
          padding: 0.375rem 0.75rem;
        }
      }
    }
    // .solid {
    //   border: 1px solid #e1e7ff;
    // }

    .company-input-container {
      .company-input-title {
        font-family: "Lato", sans-serif;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  .profile-update-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    width: 149px;
    height: 42px;

    .update-btn {
      background: #efbb20;
      font-size: 16px;
      font-family: "Lato", sans-serif;
      font-weight: 700;
      border: none;
    }

    .update-btn:hover {
      background: #efbb20;
    }
  }
}

#contactNumber {
  border: 1px solid #ced4da;
  border-radius: 0.3rem;
  padding: 0.375rem 0.75rem;
}

.profile-update-btn {
  margin-top: 50px;
  width: 100%;
  height: 42px;

  .update-btn {
    background: #efbb20;
    font-size: 16px;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    border: none;
  }
}
.close-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1051;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  color: #000;
  font-size: 1.5rem;
  cursor: pointer;
}

.update-profile-container {
  label {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
  }
}
