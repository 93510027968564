.background-banner-talent {
  background-image: url("../../../../../public/images/banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;

  .background-overlay {
    background: #0028484d;
    overflow: hidden;
    height: 100%;
    z-index: 2;
    width: 100%;
    padding: 20px;

    .banner-container {
      display: flex;
      align-items: center;
      gap: 50px;
      padding: 30px 15px 10px;

      .upload-image-container-outer {
        flex-grow: 0.5;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          font-family: "Lato", sans-serif;
          font-style: italic;
          font-weight: 500;
          font-size: 11px;
          color: #ffffff;
          margin-top: 10px;
        }

        .upload-image-container {
          display: flex;
          // align-items: center;
          .banner-image-bg {
            display: flex;
            background: #d9d9d9;
            justify-content: center;
            justify-items: center;
            align-items: center;
            width: 130px;
            height: 130px;
            border-radius: 100%;
            border: 5px solid #01b5e0;
            cursor: pointer;
            overflow: hidden;

            .banner-profile {
              width: 130px;
              height: 130px;
              object-fit: cover;
              object-position: center;
              border-radius: 100%;
            }

            .banner-icon-user {
              font-size: 79.78px;
              color: #b7bcce;
            }
          }

          .banner-camera-bg {
            display: flex;
            background: #d9d9d9;
            justify-content: center;
            justify-items: center;
            align-items: center;
            width: 32px;
            height: 32px;
            border-radius: 100%;
            border: 3px solid #7b1cf4;
            margin-left: -32px;
            .banner-icon-camera {
              color: #000000;
            }
          }
        }
      }

      .banner-profile-information {
        flex-grow: 2;
        color: #ffffff;
        font-family: "Lato", sans-serif;

        h1 {
          font-size: 24px;
          font-weight: 700;
        }

        .banner-user-info {
          display: flex;
          gap: 100px;
          margin: 30px 0 0;

          p {
            font-size: 15px;
            font-weight: 600;

            .banner-user-info-icon {
              font-size: 23px;
              margin-right: 10px;
              color: #ffffff;
            }
          }
        }
      }

      .banner-freemium-btn {
        flex-grow: 1;

        .freemium-btn {
          padding: 30px 30px;
          background: rgba(1, 181, 224, 0.75);
          border: none;
          border-radius: 5px;
          width: 100%;

          h3 {
            font-size: 18px;
            font-weight: 800;
            font-family: "Lato", sans-serif;
          }

          .freemium-btn-desc {
            display: flex;
            justify-content: left;

            .banner-user-info-icon {
              font-size: 23px;
              margin-right: 10px;
              color: #efbb20;
            }

            p {
              font-size: 15px;
              font-weight: 500;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.banner-container > * {
  flex: 0 1 0;
}

@media (max-width: 1000px) {
  .background-banner-talent {
    background-image: url("../../../../../public/images/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;

    .background-overlay {
      .banner-container {
        display: block;
        align-items: center;
        gap: 50px;
        padding: 30px 15px 10px;

        .upload-image-container-outer {
          flex-grow: 0.5;
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
}
