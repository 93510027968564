@use "../../Abstract/" as a;

.nxtLogo {
  cursor: pointer;
}

.landingNavMenu {
  a:hover {
    color: #6f64f8;
  }
  .nav-link,
  .dropdown-item {
    font-family: a.$lato;
    font-size: 16px;
    color: a.$dblue;
    font-weight: 700;
    text-transform: uppercase;
  }

  .dropdown-menu {
    border: 1px solid #fafafa;
    box-shadow: 0px 4px 4px 0px #00000040;

    @media screen and (max-width: 767px) {
      border: none;
      box-shadow: none;
    }
  }
}
