.sidebar-joblist-client {
  width: 268px;
  margin: 0;
  padding: 0;

  .job-details {
    display: flex;
    justify-content: space-between;
    margin: auto 0;
    padding: 10px;
    cursor: pointer;

    p {
      margin: auto 0;
      align-items: center;
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      color: #6f6f6f;
    }

    .icon {
      margin: auto 0;
      align-items: center;
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      color: #6f6f6f;
    }
  }

  .range-container {
    padding: 20px;
    background: #ffff;
    border: 1px solid #d9d9d9;
    border-radius: 3px;

    h5 {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      text-align: center;
      color: #232323;
    }

    h6 {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      text-align: center;
      color: #232323;

      span {
        font-weight: 500;
      }
    }
  }
}
