@use "../../../Abstract/" as a;

.getInTouch {
  padding: 30px;
  font-family: a.$rubik;

  h1 {
    text-align: center;
    color: a.$lblue2;
    font-weight: 600;
    font-size: 36px;
  }

  .information {
    margin: 50px;
    display: flex;
    gap: 50px;

    @media (max-width: 992px) {
      flex-direction: column;
      margin: 30px 0px;
      gap: 5px;
    }

    .numEmailContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .iconText {
      display: flex;
      gap: 10px;

      .icon {
        font-size: 25px;
        margin: 8px 0;
        color: a.$lblue2;
      }

      p {
        font-size: 25px;
        font-weight: 400;
        word-break: break-all;
        white-space: pre-line;
      }
    }

    .iconTextLoc {
      display: flex;
      gap: 10px;

      .icon {
        font-size: 25px;
        color: a.$lblue2;
        margin-top: 9px;
      }

      p {
        font-size: 25px;
        font-weight: 400;
        text-align: justify;
        // word-break: break-all;
        // white-space: pre-line;
      }
    }
  }
}
