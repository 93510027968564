.secretPage {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../../public/images/acquiro-element-bg.jpg");
  height: 100vh;
  //   justify-content: center;
  padding: 30px;
  font-family: "Lato", sans-serif;

  .tableContainer {
    background-color: #ffff;
    border: 1px solid #00000040;
    padding: 20px;
    display: flex;
    justify-items: center;
    height: 100%;
    flex-direction: column;

    border-radius: 5px;
    box-shadow: 0px 2px 4px 0px #00000040;

    h1 {
      color: #fff;
      margin: 0;
      font-size: 20px;
      background-color: #003763;
      padding: 10px;
      text-align: center;
    }

    .code {
      background-color: transparent !important;
      color: #003763;
      font-weight: 900;
      font-size: 25px;
      padding: 0;
      text-align: left;
    }

    .tableLabel {
      margin-top: 20px;
    }

    .inputFieldContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      div {
        display: flex;
        gap: 5px;

        button {
          border: none;
          background-color: #efbb20;
        }
      }
    }

    .tableDetailsContainer {
      overflow-y: auto;
      height: 500px;

      // border: 1px solid #003763;
      .table {
        text-align: center;
        .titleTable {
          position: sticky;
          top: 0;
          z-index: 1; /* Set z-index higher than the body to keep it on top */
          background-color: #e1e7ff;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
        }

        .bodyTable {
          max-height: 400px; /* Set the maximum height for the table body to enable scrolling */
          overflow-y: auto; /* Enable vertical scrolling when content overflows */
          margin-top: 30px; /* Create space for the fixed header */

          .bodyInside {
            td {
              padding: 10px 20px;
              text-align: center;
            }
          }
        }
      }

      .alertMessage {
        margin: 0px;
        border: none;
      }
    }
    // .table {
    //   font-family: "Lato", sans-serif;
    //   text-align: center;
    //   height: 500px;

    //   .titleTable {
    //     th {
    //       padding: 10px;
    //       width: 25%;
    //       border: 1px solid black;
    //     }
    //   }
    //   .bodyInside {
    //     td {
    //       height: fit-content;
    //       padding: 10px;
    //       width: 25%;
    //       border: 1px solid black;
    //     }
    //   }
    // }
  }
}
