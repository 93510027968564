.coming-soon{
    background-image: url('../../../public/images/acquiro-element-bg-dark.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      font-size: 20px;
      font-weight: 300;
      color: #FFF;
      text-transform: uppercase;
      margin-top: 5rem;
    }
    h1 {
      font-size: 5rem;
      margin-bottom: .5rem;
      color: #efbb20 !important;
      font-weight: 700;
      text-transform: uppercase;
    }
  
    p {
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 4rem;
      text-transform: uppercase;
      color: #FFF;
      text-align: center;
    }
    h4 {
      font-size: 14px;
      color: white;
      text-align: center;
      font-weight: 300;
      margin-top: 2rem;
    }
    button {
      width: 100%;
      height: 50px;
      padding: 10px;
      border: none;
      background-color: #efbb20;
      color: white;
      border-radius: 5px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      margin-top: 10px;
      margin-bottom: 30px;
    }
    .logo {
        margin-bottom: 1rem;
        width: 248px;
        height: 88px;
    }
  }
  // .logo {
  //   // margin-bottom: 7rem;
  // }
  .btn-label {
    color:#FFF;
    font-weight: 500;
    text-decoration: none;
  }
  .btn-label:hover {
    color: #FFF;
  }