@use "../abstract/" as a;

.bg-container {
  width: 100%;
  background-color: #8046fa;
  height: 100%;
  position: relative;
  overflow: hidden;

  .big-container__bg-section-one {
    .bg-girl {
      width: 420px;
      display: flex;
      margin: 0 auto;
    }
  }

  .bg-cotainer__text-body {
    position: relative;
    .bg-cotainer__text-body__para-one {
      font-weight: 400;
      font-size: 19px;
      text-align: center;
      margin-top: -47px;
      padding: 30px;
      color: #fff;
    }
    .bg-cotainer__text-body__header-one {
      .bg-cotainer__text-body__para-two {
        font-weight: 600;
        font-size: 63px;
        display: table;
        margin: 0 auto;
        margin-bottom: 26px;
        line-height: 1.3;
        color: #fff;

        text {
          color: a.$yellow2;
        }
      }
    }
    .bg-cotainer__text-body__para-three {
      font-weight: 700;
      font-size: 21px;
      text-align: center;
      padding: 40px;
      padding-top: 35px;
      color: #fff;
    }
    .bg-cotainer__text-body__para-four {
      font-weight: 600;
      font-size: 16px;
      text-align: left;
      display: table;
      margin: 0 auto;
      margin-left: 111px;
      line-height: 24px;
      margin-top: -5px;
      color: #fff;

      @media (max-width: 360px) {
        font-weight: 600;
        font-size: 16px;
        text-align: left;
        display: table;
        margin: 0 auto;
        margin-left: 77px;
        line-height: 24px;
        margin-top: -5px;
        color: #fff;
      }

      .check-img {
        width: 20px;
        position: absolute;
        left: 86px;

        @media (max-width: 360px) {
          left: 52px;
        }
      }
    }

    .bg-cotainer__text-body__para-five {
      font-weight: 600;
      font-size: 36px;
      text-align: center;
      margin-top: -28px;
      color: #fff;
    }

    button {
      display: flex;
      margin: 0 auto;
      background-color: #ffb601;
      color: #ffffff;
      border: none;
      border-radius: 27px;
      padding: 15px 32px;
      font-size: 29px;
      font-weight: 600;
      margin-top: 19px;
      margin-bottom: 31px;

      @media (max-width: 360px) {
        font-size: 25px;
      }
    }
  }

  .bg-container__key-features {
    .bg-container__keyfeatures-allside {
      max-width: fit-content;
      margin: 0 auto;
      display: flex;
      color: #fff;

      .side-a,
      .side-b {
        margin: 0.2rem;
      }
    }
  }

  .bg-container__improve-talent {
    .nxt-logo {
      width: 52%;
      display: flex;
      margin: 0 auto;
      margin-top: -45px;
    }
  }

  .bg-container__improve-talent__container {
    .bg-container__improve-talent__column {
      max-width: fit-content;
      margin: 0 auto;
      display: flex;
      text-align: center;

      .first,
      .second {
        .one,
        .two {
          width: 40%;
        }

        .three {
          width: 20%;
        }
      }

      //   .first {
      //     .three {
      //       width: 100%;
      //     }

      //     .four {
      //       width: 50%;
      //     }
      //   }

      //   .first {
      //     .five {
      //       width: 60%;
      //     }
      //   }
      h6 {
        font-weight: bolder;
        font-size: 12px;
        margin-top: 8px;
        color: #fff;
      }

      p {
        font-weight: 400;
        font-size: 12px;
        margin-top: 8px;
        color: #fff;
      }

      .last {
        padding: 0px 48px;

        @media (max-width: 500px) {
          padding: 0px 105px;
        }
        @media (max-width: 375px) {
          padding: 0px 77px;
        }
      }
      //   .first {
      //     .one {
      //       width: 60%;
      //     }
      //   }
      //   .second {
      //     .two {
      //       width: 60%;
      //     }
      //   }
    }
  }

  .bg-container__sign-up {
    margin-top: 44px;

    .bg-cotainer__text-body__para-one {
      font-weight: 250;
      font-size: 19px;
    }
  }

  .bg-stars {
    width: 96%;
    position: absolute;
    top: 20.5%;
    left: 15px;

    @media (max-width: 360px) {
      top: 21.4%;
    }
  }

  .bg-vector {
    width: 106%;
    position: absolute;
    top: 34%;
  }

  .bg-shape {
    width: 78%;
    position: absolute;
    top: 54.5%;
    right: 29px;
  }

  .bg-vector2 {
    width: 205%;
    position: absolute;
    top: 64.6%;
    right: -271px;
  }
  .bg-confetti {
    width: 90%;
    position: absolute;
    top: 91.9%;
    right: 10px;

    @media (max-width: 360px) {
      top: 91.9%;
      right: -7px;
    }
  }
}
