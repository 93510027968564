.superAdminNavbar {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #6f64f8;
  color: #ffff;
  align-items: center;

  h4 {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 0;

    span {
      font-weight: 500;
    }
  }

  h5 {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 0;

    span {
      font-weight: 500;
    }
  }
}
