.socialMediaShareContainer {
  display: flex;
  gap: 5px;
  margin: 10px 0;

  .copyIcon,
  .envelopeIcon {
    margin: auto 0;
    padding: 0;
    color: blueviolet;
  }

  // .disable {
  //   background-color: #ababab;
  // }
}
