@use "../../Abstract/index" as a;

#bottomButton {
  left: 48%;
  position: fixed;
  bottom: 7%;
  width: 147px;
  height: 110px;
  margin: 0 10px 10px 5.5%;
  padding: 10px;
  cursor: pointer;
  z-index: 99;

  @media screen and (max-width: 992px) {
    bottom: 2%;
    left: -2%;
    width: 125px;
    height: 95px;
  }

  //   @media screen and (max-width: 500px) {
  //     width: 20%;
  //     height: 15%;
  //   }
}

.tilt-shake {
  animation: tilt-shaking 0.7s infinite;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(5deg);
  }
  20% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-5deg);
  }
  40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* _bottomButton.scss */
.modals {
  position: fixed;
  top: 0;
  left: 0;
  // width: 100%; /* Adjust the width as needed */
  // height: 100%; /* Adjust the height as needed */
  // background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 10;

  @media screen and (max-width: 992px) {
    margin-left: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.modal-contents {
  background-image: url("../../../../assets/images/Background/Outside/modal_bg.png");
  background-size: 100% 100%;
  width: 45%;
  height: 500px;
  top: 100px;
  padding: 2% 3%;
  margin-left: 5.8%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: fixed;
  font-family: "Rubik", sans-serif;
  border-radius: 36px;

  @media (max-height: 700px) {
    top: 30px;
  }

  //   @media screen and (max-width: 992px) {
  //     width: 80%;
  //     height: 80%;
  //     margin: auto;
  //     top: 0;
  //     padding: 1.5% 10%;
  //     // top: 50%;
  //     // bottom: 50%;
  //   }

  @media screen and (max-width: 992px) {
    background-image: none;
    background-color: rgba(71, 66, 106, 255);
    width: 80%;
    height: 80%;
    margin: auto;
    top: 0;
    padding: 5% 2.5% 2.5% 2.5%;
    bottom: 0%;
  }

  @media (max-width: 768px) {
    width: 90%;
    left: 5%;
  }

  @media (max-width: 625px) {
    width: 388px;
    height: 500px;
    left: 5%;
    top: -17%;
  }

  @media (max-width: 400px) {
    width: 340px;
    left: 5%;
    top: -17%;
  }

  @media (max-width: 375px) {
    width: 330px;
    left: 5%;
    top: -17%;
  }

  @media (max-width: 320px) {
    width: 280px;
    left: 5%;
    top: -17%;
  }

  .modal_title_xmark {
    width: 100%;
    display: flex;
    position: absolute;
    z-index: 1;
    right: 20px;
    // justify-content: center;

    // p {
    //   text-align: left;
    //   color: #fff !important;
    //   line-height: 36px;
    // }

    // h1 {
    //   width: 100%;
    //   font-size: 32px;
    //   font-weight: 500;
    //   text-align: center;
    //   color: #fff;
    //   margin-bottom: 9%;

    // @media screen and (max-width: 992px) {
    //   width: 100%;
    //   font-size: 25px;
    //   margin-bottom: 3%;
    //   // margin-bottom: 20%;
    //   // margin-bottom: 20%;
    //   // flex: 1;
    // }

    // @media screen and (max-height: 500px) {
    //   font-size: 15px;
    //   margin-bottom: 0%;
    // }

    //   @media screen and (max-width: 992px) and (max-height: 700px) {
    //     margin-top: 0%;
    //     padding: 0 20px;
    //     margin-right: 5%;

    //     // flex: 1;
    //   }

    //   @media screen and (max-width: 500px) {
    //     margin-left: -15px;
    //   }
    // }

    .modal_close_icon {
      font-size: 35px;
      color: #818181;
      cursor: pointer;
      float: right;
      // position: fixed;
      // right: 0;

      @media screen and (max-width: 992px) {
        margin-right: 15px;
      }
    }
  }

  .notice_modal_paragraph {
    height: 85%;
    // overflow-y: scroll;
    padding-left: 0%;
    padding-right: 5%;
    margin-right: 35%;
    margin-top: 0%;

    // p,
    // ol {
    //   text-align: left;
    //   color: #fff !important;
    //   line-height: 36px;
    // }
    // // p {
    // //   &:nth-child(2) {
    // //     font-size: 36px;
    // //     color: #ffc700 !important;
    // //   }
    // // }
    // @media screen and (max-width: 992px) {
    //   margin-right: 0%;
    // }

    // @media screen and (max-height: 500px) {
    //   font-size: 12px;

    //   //   margin-bottom: 0%;
    // }

    p {
      font-family: a.$rubik;
      font-size: clamp(0.3rem, 0.8vw, 1rem);
      font-weight: 500;

      // @media screen and (max-height: 500px) {
      //   font-size: 12px;
      //   //   margin-bottom: 0%;
      // }
    }
  }

  .carousel-inner {
    height: 90%;
    width: 65% !important;

    a {
      color: #01b5e0;
    }
    .carousel-item1,
    .carousel-item2 {
      line-height: 2.5;
      height: 400px;
      li::marker {
        color: #ffc700;
      }
      overflow-y: scroll;
      // @media screen and (max-width: 992px) and (max-height: 1000px) {
      //   margin-top: 30px;
      // }

      // @media screen and (max-width: 992px) and (min-height: 1000px) {
      //   margin-top: 10%;
      // }

      // @media screen and (max-width: 992px) and (max-height: 600px) {
      //   margin-top: 0%;
      // }

      // @media screen and (max-width: 772px) and (max-height: 600px) {
      //   margin-top: 4%;
      // }

      @media screen and (max-width: 600px) {
        margin-top: 45px;
        height: 395px;
      }

      @media (max-width: 320px) {
      }
    }
    p {
      text-align: left;
      color: #fff !important;
      // line-height: 31px;
      font-size: clamp(0.8rem, 2vw, 1rem);
      // @media screen and (max-width: 2560px) {
      //   font-size: 25px;
      //   line-height: 38px;
      // }
      // @media screen and (max-width: 1440px) {
      //   font-size: 14px;
      //   line-height: 38px;
      // }
      // @media screen and (max-width: 1024px) {
      //   font-size: 14px;
      //   line-height: 28px;
      // }
    }

    span {
      font-family: "Rubik";
      color: #ffc700;
      font-weight: 700;
      display: inline-block;
      margin-top: 5px;
      font-size: 18px;
      line-height: 0.1;
      font-size: clamp(0.8rem, 2vw, 1rem);

      // @media screen and (max-width: 2560px) {
      //   margin-top: 20px;
      //   margin-bottom: 25px;
      //   font-size: 30px;
      // }
      // @media screen and (max-width: 1440px) {
      //   margin-top: 30px;
      //   margin-bottom: 25px;
      //   font-size: 18px;
      // }
    }

    .noticeLink {
      color: #01b5e0;
    }
  }

  #carouselExampleIndicators {
    .carousel-indicators {
      bottom: -15px !important;
      .circle-btn {
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
    }

    .carousel-item1::-webkit-scrollbar {
      width: 10px;

      @media (max-width: 600px) {
        width: 5px;
        margin-top: 20px;
      }
    }

    .carousel-item1 ::-webkit-scrollbar-track {
      background-color: #e4e4e4;
      border-radius: 100px;
    }
    .carousel-item1::-webkit-scrollbar-thumb {
      background-image: linear-gradient(180deg, #b3b3b3 0%, #a9a9a9 99%);
      box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
      border-radius: 100px;
    }

    .carousel-item2::-webkit-scrollbar {
      width: 10px;

      @media (max-width: 600px) {
        width: 5px;
      }
    }

    .carousel-item2 ::-webkit-scrollbar-track {
      background-color: #e4e4e4;
      border-radius: 100px;
    }
    .carousel-item2::-webkit-scrollbar-thumb {
      background-image: linear-gradient(180deg, #b3b3b3 0%, #a9a9a9 99%);
      box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
      border-radius: 100px;
    }

    @media (max-width: 600px) {
      display: flex;
      justify-content: center;
      width: 113%;
      right: 25px;
    }

    @media (max-width: 800px) {
      display: flex;
      justify-content: center;
    }
  }
}
