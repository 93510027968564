@use "../../Abstract/" as a;

.customTextArea {
  display: flex;
  flex-direction: column;

  textarea {
    margin: 10px 0;
    height: 120px;
    padding: 10px;
  }

  .note {
    border: 1px solid #d9d9d9;
  }
}

.noteContainer {
  label {
    color: #232323;
    font-size: 14px;
    font-family: a.$lato;
  }
}
