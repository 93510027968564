@use "../../Abstract/" as a;

.separate {
  display: flex;
  align-items: center;
  gap: 20px;

  hr {
    flex: 1;
    border: none;
    border-top: 1px solid #e1e7ff;
    margin: 0;
  }

  p {
    font-size: 16px;
    font-family: a.$inter;
    font-weight: 400;
    color: #232323;
  }
}

.socialMediaIcons {
  margin: 10px 0 20px;
  display: flex;
  justify-content: center;
  gap: 30px;
  cursor: pointer;

  .fbLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9.5px 14px;
    border: 1px solid #dadce0;
    border-radius: 50%;
  }

  .googleLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9.5px 10px;
    border: 1px solid #dadce0;
    border-radius: 50%;
  }

  .fbSpinner {
    border-radius: 50%;
    border: 1px solid #dadce0;
    padding: 7px 11px;
  }
}
