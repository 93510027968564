@import "../scss/layout/message";

.add-job-form-container {
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #00000040;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;

  label {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }

  h6 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #003763;
  }

  // input,
  // select,
  // textarea {
  //   border: 1px solid #e1e6eb;
  //   // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  //   border-radius: 5px;
  // }

  .hiring-manager-info-container {
    input {
      border: 1px solid #e1e6eb;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
    }

    .hiring-manager-info-fields {
      display: flex;
      gap: 20px;

      .fullname-group,
      .email-group {
        flex: 1 1 0;
      }

      .contact-number-group {
        #hiringContact {
          padding: 6px;
        }
        .error-msg {
          margin-top: 4px;
        }
      }
    }
  }

  .job-information-container {
    .job-title-type-container {
      display: flex;
      gap: 20px;

      input {
        border: 1px solid #e1e6eb;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }

      div {
        flex: 1 1 0;
      }
    }

    .work-arrangement-office-hours-container {
      display: flex;
      gap: 20px;

      select {
        border: 1px solid #e1e6eb;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }

      .work-arrangement-group,
      .office-hours-group {
        flex: 1 1 0;
      }

      .start-end-time-container {
        display: flex;
        gap: 20px;

        input {
          border: 1px solid #e1e6eb;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
        }

        div {
          flex: 1 1 0;
        }
      }
    }

    .address-city-container {
      display: flex;
      gap: 20px;

      input {
        border: 1px solid #e1e6eb;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }

      .office-address-group {
        flex: 2 1 0;
      }

      .city-group {
        select {
          border: 1px solid #e1e6eb;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
        }

        flex: 1 1 0;
      }
    }

    .salary-group {
      input {
        border: 1px solid #e1e6eb;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }

      .max-min-salary {
        display: flex;
        width: 100%;
        // align-items: center;
        // background-color: #003763;

        gap: 20px;

        .range {
          align-items: center;
          margin-top: 5px;
          font-weight: bold;
        }

        div {
          width: 100%;
        }

        .salary-code-input-container {
          display: flex;
          align-items: center;
          position: relative;
        }

        .php-code {
          position: absolute;
          left: 8px;
        }

        #minSalary,
        #maxSalary {
          padding: 7px 20px;
          border: solid 1px #ccc;
          border-radius: 5px;
        }
      }
    }

    .benefits-group {
      .css-13cymwt-control {
        border: 1px solid #e1e6eb;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }
    }

    .quill {
      // border: 1px solid #e1e6eb;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;

      .ql-toolbar {
        border: 1px solid #e1e6eb;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        box-shadow: 0px 2px 4px -4px rgba(0, 0, 0, 0.25);
      }

      .ql-container {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border: 1px solid #e1e6eb;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 2px 4px -4px rgba(0, 0, 0, 0.25);
      }
    }

    .job-overview-group,
    .job-description,
    .qualification-group {
      textarea {
        border: 1px solid #e1e6eb;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }

      .charactersLength {
        font-size: 14px;
        color: gray;
        // background-color: #003763;
        margin-top: 10px;
        text-align: right;
      }
    }
  }

  .job-requirements-container {
    .skill-set-dialect-fields {
      display: flex;
      gap: 20px;

      .skill-set-group,
      .dialect-group {
        flex: 1 1 0;
      }
    }

    .css-13cymwt-control {
      border: 1px solid #e1e6eb;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
    }
  }

  .start-end-date-fields {
    display: flex;
    gap: 20px;

    div {
      flex: 1 1 0;
    }

    input {
      border: 1px solid #e1e6eb;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
    }
  }

  .actions-button-container {
    padding-top: 50px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;

    .btn-draft {
      background: #efbb20;
      border-radius: 3px;
      border: none;
    }

    .btn-save {
      background: #003763;
      border-radius: 3px;
      border: none;

      .icon-publish {
        margin-right: 5px;
      }
    }
  }
}

// .label-subText-library {
//   width: 100%;
//   margin-top: 0.25rem;
//   font-size: 0.875em;
//   color: #f64e60;
//   font-weight: 600;
//   text-align: left;
// }
.required-field {
  color: red;
  margin-left: 2px;
}
.formInput {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.label-text {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  font-family: "Lato", sans-serif;
}
// .label-subText {
//   font-size: 15px;
//   font-weight: 600;
//   text-align: left;
//   font-family: "Lato", sans-serif;
// }

.label-textOptional {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #000000;
}
.label-optional {
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  margin-left: 5px;
  color: grey;
}
.label-subTextRemain {
  font-size: 13px;
  font-weight: 300;
  text-align: center;
}
.label-subTextRemainDanger {
  font-size: 13px;
  font-weight: 00;
  text-align: center;
  color: #ff0e0e !important;
}

@media (max-width: 992px) {
  .add-job-form-container {
    .hiring-manager-info-container {
      .hiring-manager-info-fields {
        display: block;
      }
    }

    .job-information-container {
      .job-information-fields {
        .job-title-type-container {
          display: block;
        }

        .work-arrangement-office-hours-container {
          display: block;

          .start-end-time-container {
            display: block;
          }
        }

        .address-city-container {
          display: block;
        }
      }

      .salary-group {
        width: 100%;
      }
    }

    .job-requirements-container {
      .skill-set-dialect-fields {
        display: block;
      }
    }

    .posting-date-container {
      .start-end-date-fields {
        display: block;
      }
    }

    .actions-button-container {
      display: block;
      button {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}
