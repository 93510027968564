.jobPostingChart {
  background-color: #fff;
  width: 100%;
  padding: 10px 20px;

  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #161616;
  }
}
