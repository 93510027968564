.landing-postJob {
  background-color: #ffff;
  padding: 30px 0;
  font-family: "Rubik", sans-serif;
  .postJob-body {
    display: flex;
    background-color: #f5f5f5;
    padding: 20px 20px 0px 20px;
    border-radius: 20px;
    margin: 0 20px;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 30px;
      text-align: center;
      margin: 0 0px;
    }
    .postJob-img-container {
      display: flex;
      justify-content: center;
      position: relative;
      right: 20px;
      .postJob-img {
        width: 60%;
      }
    }
    .postJob-reg {
      flex-direction: column;
      position: relative;
      right: 40px;
      padding-right: 20px;
      //   flex: 0 0 auto;

      @media (max-width: 768px) {
        padding-right: 0px;
        flex-direction: column;
        padding-right: 20px;
      }

      @media (max-width: 425px) {
        padding-right: 0px;
        position: relative;
        left: 9px;
      }

      p {
        color: #8046fa;
        word-spacing: 10px;
        text-align: left;
        margin-bottom: 0;
        font-weight: bold;
        font-size: 23px;
      }

      h1 {
        color: #8046fa;
        word-spacing: 9px;
        text-align: left;
        font-weight: bold;
        font-size: 23px;
      }

      button {
        background-color: #01b5e0;
        border: 1px solid #01b5e0;
        border-radius: 30px;
        color: #ffffff;
        font-size: 18px;
        width: 200px;
        padding: 10px 20px;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      button:hover {
        background-color: #02cbfb;
        border: 1px solid #02cbfb;
      }
    }

    .postJob-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      right: 20px;

      @media (max-width: 425px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        right: -29px;
      }
    }
  }
}
