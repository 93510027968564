.sidebar-js-container {
  width: 268px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 20px;
  background: #ffff;

  .js-header {
    h5 {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: #002848;
    }
  }

  .statistics-content {
    margin-top: 10px;
    .stats-section {
      display: flex;
      justify-content: space-between;

      p {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #333333;
        margin: 3px 0;
      }
    }
  }
}
