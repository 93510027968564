@use "../Abstract/" as a;

.pricing__content {
  background-color: #ffffff;
  padding: 30px 30px 60px 30px;

  .pricing__content--title {
    width: 100%;
    text-align: center;

    h2 {
      font-weight: 700;
      font-size: clamp(1.8rem, 2.5vw, 2rem);
      font-family: a.$rubik;
      color: #7352e9;

      // @media (max-width: 320px) {
      //   font-size: 30.27px;
      // }
    }

    h3 {
      font-size: clamp(1.5rem, 2.5vw, 1rem);
      font-weight: 500;
      font-family: a.$rubik;
      color: #232323;

      @media (max-width: 600px) {
        font-size: clamp(1.3rem, 2.5vw, 1rem);
        padding: 0 30px 0 30px;
        color: #232323;
      }
    }
  }

  .pricing__content--card--list {
    width: 100%;
    display: flex;
    justify-content: center;

    .elite {
      margin-left: 29%;
    }

    .pricing__content--container__normal {
      margin-top: 25px;
      margin-right: 5px;
      min-height: 600px;
      width: 350px;
      background-color: #eff0ff;
      border-radius: 44px;
      padding: 35px;
      box-shadow: 0px 24px 83px 0px #0000001a;
      box-shadow: 0px 5px 18px 0px #0000000f;
      box-shadow: 0px 2px 6px 0px #0000000a;
    }

    .pricing__content--container__white {
      min-height: 651px;
      width: 350px;
      position: absolute;
      background-color: #fff;
      border-radius: 44px;
      padding: 35px;
      box-shadow: 0px 24px 83px 0px #0000001a;
      box-shadow: 0px 5px 18px 0px #0000000f;
      box-shadow: 0px 2px 6px 0px #0000000a;

      @media (max-width: 2560px) {
        right: 30.5%;
        transform: translate(-50%, 0);
      }

      @media (max-width: 1920px) {
        right: 24%;
        transform: translate(-50%, 0);
      }
      @media (max-width: 1440px) {
        right: 15%;
        transform: translate(-50%, 0);
      }
      @media (max-width: 1280px) {
        right: 13%;
        transform: translate(-50%, 0);
      }

      .most__popular {
        width: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #2b1c50;
        padding: 10px;
        text-align: center;
        border-top-left-radius: 44px;
        border-top-right-radius: 44px;

        h1 {
          margin: auto;
          font-size: 20px;
          color: #fff;
        }
      }
    }

    .pricing__content--card--title {
      font-family: a.$rubik;
      color: #2b1c50;

      h1 {
        font-size: 35px;
        font-weight: 700;
      }

      button {
        border-radius: 20px;
        background-color: #ff4df8;
        border: 5px solid #ff99fc;
      }

      .pricing__content--card--amount {
        gap: 10px;
        width: 100%;
        display: flex;
        // flex-wrap: wrap;

        h2 {
          font-weight: 700;
          font-size: 29px;
          text-wrap: nowrap;
          margin: auto;
        }

        .pricing__content--card--amount--details {
          width: 100%;
          label {
            font-family: a.$inter;
            font-weight: 600;
            font-size: 12px;
            margin: 0;
          }

          p {
            margin: 0;
            font-family: a.$inter;
            font-weight: 500;
            font-size: 11px;
          }
        }
      }
    }

    .pricing__content--card--key__features {
      h5 {
        font-size: 14.62px;
        font-weight: bold;
        font-family: a.$rubik;
        color: #2b1c50;
      }

      ul {
        li {
          font-size: 14px;
          color: #111019;
        }
      }
    }
  }

  .pricing__content--sp {
    width: 100%;
    display: grid;
    justify-content: center;
    @media (max-width: 600px) {
      width: auto;
    }

    .elite {
      margin-left: 29%;

      @media (min-width: 601px) and (max-width: 768px) {
        margin-left: 37%;
      }

      @media (max-width: 600px) {
        margin-left: 0;
      }
    }

    .pricing__content--container__normal {
      margin-top: 25px;
      min-height: 600px;
      width: 350px;
      background-color: #eff0ff;
      border-radius: 44px;
      padding: 35px;
      box-shadow: 0px 24px 83px 0px #0000001a;
      box-shadow: 0px 5px 18px 0px #0000000f;
      box-shadow: 0px 2px 6px 0px #0000000a;

      @media (min-width: 601px) and (max-width: 768px) {
        width: 247px;
        padding: 30px;
      }
    }

    @media (max-width: 600px) {
      width: 100%;
      min-height: 30%;
      margin-top: 18px;
    }

    .pricing__content--container__white {
      min-height: 651px;
      width: 350px;
      position: absolute;
      background-color: #fff;
      border-radius: 44px;
      padding: 35px;
      box-shadow: 0px 24px 83px 0px #0000001a;
      box-shadow: 0px 5px 18px 0px #0000000f;
      box-shadow: 0px 2px 6px 0px #0000000a;

      @media (min-width: 601px) and (max-width: 768px) {
        width: 280px;
      }

      @media (max-width: 600px) {
        position: relative;
        min-height: 30%;
      }

      .most__popular {
        width: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #2b1c50;
        padding: 10px;
        text-align: center;
        border-top-left-radius: 44px;
        border-top-right-radius: 44px;

        h1 {
          margin: auto;
          font-size: 20px;
          color: #fff;
        }
      }
    }

    .pricing__content--card--title {
      font-family: a.$rubik;
      color: #2b1c50;

      h1 {
        font-size: 35px;
        font-weight: 700;
        letter-spacing: -3px;

        @media (min-width: 601px) and (max-width: 768px) {
          font-size: clamp(1.3rem, 10.3vw, 2rem);
        }
        @media (max-width: 600px) {
          font-size: clamp(1.5rem, 10.5vw, 3rem);
        }
      }

      button {
        border-radius: 20px;
        background-color: #ff4df8;
        border: 5px solid #ff99fc;
      }

      .pricing__content--card--amount {
        gap: 10px;
        width: 100%;
        display: flex;
        // flex-wrap: wrap;

        h2 {
          font-weight: 700;
          font-size: 29px;
          text-wrap: nowrap;
          margin: auto;

          @media (min-width: 601px) and (max-width: 768px) {
            font-size: clamp(1.3rem, 6vw, 1rem);
          }
          @media (max-width: 600px) {
            font-size: clamp(1.2rem, 8vw, 3rem);
          }
        }

        .pricing__content--card--amount--details {
          width: 100%;
          label {
            font-family: a.$inter;
            font-weight: 600;
            font-size: 12px;
            margin: 0;

            @media (min-width: 601px) and (max-width: 768px) {
              font-size: clamp(0.3rem, 1.5vw, 3rem);
            }

            @media (max-width: 600px) {
              font-size: clamp(0.5rem, 3vw, 3rem);
            }
          }

          p {
            margin: 0;
            font-family: a.$inter;
            font-weight: 500;
            font-size: 11px;

            @media (min-width: 601px) and (max-width: 768px) {
              font-size: clamp(0.2rem, 1.3vw, 3rem);
            }

            @media (max-width: 600px) {
              font-size: clamp(0.5rem, 2.8vw, 3rem);
            }
          }
        }
      }
    }

    .pricing__content--card--key__features {
      h5 {
        font-size: 14.62px;
        font-family: a.$rubik;
        color: #111019;
        font-weight: bold;
      }

      ul {
        li {
          font-size: 15px;
          font-weight: 800;
          color: #111019;
        }
      }
    }
  }
}
