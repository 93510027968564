.landing-about {
  background-color: #ffffff;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 30px 0;

  .landing-content {
    display: flex;
    gap: 40px;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }

    .about-img-container {
      border-radius: 20px;
      padding: 20px;
      background-color: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 300px;

        @media (max-width: 400px) {
          width: 100px;
        }
      }
    }

    .about-body {
      h1 {
        font-family: "Rubik", sans-serif;
        color: #8046fa;
        font-size: 32px;
        font-weight: bolder;
      }

      p {
        font-family: "Rubik", sans-serif;
        font-weight: 500;
      }

      button {
        background-color: #01b5e0;
        border: 1px solid #01b5e0;
        border-radius: 30px;
        color: #ffffff;
        font-size: 18px;
        width: 200px;
        padding: 10px 20px;
      }

      button:hover {
        background-color: #02cbfb;
        border: 1px solid #02cbfb;
      }
    }
  }
}
