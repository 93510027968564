.manage-user-container {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 30px;

  h6 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #003763;
  }

  h5 {
    color: #57606a;
    -webkit-transform: scale(1.8, 1.2); /* Safari and Chrome */
    -moz-transform: scale(1.8, 1.2); /* Firefox */
    -ms-transform: scale(1.8, 1.2); /* IE 9 */
    -o-transform: scale(1.8, 1.2); /* Opera */
    transform: scale(1.8, 1.2); /* W3C */
  }

  .manage__btn-adduser {
    background-color: #1d9ce6;
    color: #fff;
  }

  .manage__btn-edituser {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 8px;
    background-color: #3085d6;
    color: #fff;
    font-size: 20px;
  }

  .manage__btn-deleteuser {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 8px;
    background-color: #d10000;
    color: #fff;
    font-size: 20px;
  }
}
