@use "../../../Abstract/" as a;

.profile_ats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: a.$poppins;

  .profile_ats_personal {
    display: flex;
    gap: 20px;
    .font-awesome__user {
      height: 125px;
      width: 125px;
      background-color: #d9d9d9;
      border-radius: 100%;
      font-size: 5rem;
    }

    img {
      width: 125px;
      height: 125px;
      border-radius: 100%;
    }
    .profile_ats_personal_name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #002848;
      font-weight: 600;

      h1 {
        font-size: 32px;
      }

      h5 {
        font-size: 16px;
      }
    }
  }
  .profile_ats_dropdown_container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .profile-invite__to_apply {
      background-color: #3085d6;
      border: 0;
      font-size: 14px;
    }

    .dropdown {
      .dropdown-toggle {
        min-width: 200px;
        background-color: #fff;
        border: 1px solid #013763;
        color: #013763;
      }
    }
  }

  .dropdown-menu {
    background-color: #fff;

    label {
      font-family: a.$lato;
      margin: 5px 20px;
      font-size: 14px;
    }

    .date,
    .remarks {
      width: 86.5%;
    }
  }
}
