.invalidCode {
  display: flex;
  text-align: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffff;
  font-family: "Lato", sans-serif;
  font-weight: 600;

  .invalidCodeInside {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    // background-color: rgb(231, 186, 128) !important;

    height: 100vh;
    justify-content: center;
    text-align: center;

    h1 {
      font-size: 40px;
    }

    h4 {
      font-size: 20px;
    }

    p {
      font-size: 17px;
    }

    .icon {
      width: 95.85px;
      height: 95.85px;
      color: #efbb20;
      margin-bottom: 20px;
    }

    .button-sec {
      button {
        width: 250px;
        background-color: #efbb20;
        border: none;
      }
    }
  }
}
