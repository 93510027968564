.jobPostChart {
  background-color: #fff;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #161616;
  }

  h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 36px;
    font-weight: 400;
  }

  .total {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 772px) {
      margin: 20px;
    }
  }

  .otherJobStats {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 772px) {
      display: block;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
