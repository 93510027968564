.dragAndDrop {
  display: flex;
  max-height: 1000px;
  width: 100%;
  overflow-x: scroll;
  // overflow-y: hidden;

  .floating-btn__dnd {
    position: fixed;
    left: 45%;
    top: 80%;

    .floating-btn_circlelabel {
      height: 25px;
      width: 25px;
      background: #1d9ce6;
      border-radius: 50%;
      font-size: 17.5px;
    }

    button {
      border-radius: 50px;
      background: #6f64f8;
      border: 0;
    }
  }
}
