.landing-topcompanies {
  background-color: #ffff;
  padding: 30px 0;

  @media (max-width: 575px) {
    padding: 10px 0;
  }
  .topcompanies-body {
    background-color: #f5f5f5;
    border-radius: 10px;
    margin: 30px 0;
    padding: 20px;
    font-family: "Rubik", sans-serif;
    h1 {
      font-family: "Rubik", sans-serif;

      color: #8046fa;
      font-size: 24px;
      font-weight: bolder;
      margin: 0 auto;
      text-align: center;

      @media (max-width: 575px) {
        margin-bottom: 10px;
      }
    }
    .topcompanies-inner {
      display: flex;
      flex-wrap: wrap;
      //   width: fit-content;
      .topcompanies-img-container {
        background-color: #ffffff;
        padding: 10px;
        border-radius: 5px;
        text-align: center;
        margin: 10px;
        flex: 1 0 0%;
        // display: flex;
        // justify-content: center;

        @media (max-width: 575px) {
          display: none;
        }

        .topcompanies-img {
          width: 200px;
        }
      }

      .carousel-container {
        display: none;

        @media (max-width: 575px) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .landing-carousel-item {
          //   margin: 10px;
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
