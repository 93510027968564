@use "../Abstract/" as a;

.pricing-content__pc {
  font-family: "Inter", sans-serif;
  margin-top: 80px;
  width: 100%;
  background-color: #0132dc;

  //   .pricing__content--card--list {
  //     width: 100%;
  //     display: flex;
  //     justify-content: center;

  //     .elite {
  //       margin-left: 29%;
  //     }

  //     .pricing__content--container__normal {
  //       margin-top: 25px;
  //       margin-right: 5px;
  //       min-height: 600px;
  //       width: 350px;
  //       background-color: #eff0ff;
  //       border-radius: 44px;
  //       padding: 35px;
  //       box-shadow: 0px 24px 83px 0px #0000001a;
  //       box-shadow: 0px 5px 18px 0px #0000000f;
  //       box-shadow: 0px 2px 6px 0px #0000000a;
  //     }

  //     .pricing__content--container__white {
  //       min-height: 651px;
  //       width: 350px;
  //       position: absolute;
  //       background-color: #fff;
  //       border-radius: 44px;
  //       padding: 35px;
  //       box-shadow: 0px 24px 83px 0px #0000001a;
  //       box-shadow: 0px 5px 18px 0px #0000000f;
  //       box-shadow: 0px 2px 6px 0px #0000000a;

  //       @media (max-width: 2560px) {
  //         right: 30.5%;
  //         transform: translate(-50%, 0);
  //       }

  //       @media (max-width: 1920px) {
  //         right: 24%;
  //         transform: translate(-50%, 0);
  //       }
  //       @media (max-width: 1440px) {
  //         right: 15%;
  //         transform: translate(-50%, 0);
  //       }
  //       @media (max-width: 1280px) {
  //         right: 13%;
  //         transform: translate(-50%, 0);
  //       }

  //       .most__popular {
  //         width: 100%;
  //         position: absolute;
  //         top: 0;
  //         left: 50%;
  //         transform: translateX(-50%);
  //         background-color: #2b1c50;
  //         padding: 10px;
  //         text-align: center;
  //         border-top-left-radius: 44px;
  //         border-top-right-radius: 44px;

  //         h1 {
  //           margin: auto;
  //           font-size: 20px;
  //           color: #fff;
  //         }
  //       }
  //     }

  //     .pricing__content--card--title {
  //       font-family: a.$rubik;
  //       color: #2b1c50;

  //       h1 {
  //         font-size: 35px;
  //         font-weight: 700;
  //       }

  //       button {
  //         border-radius: 20px;
  //         background-color: #ff4df8;
  //         border: 5px solid #ff99fc;
  //       }

  //       .pricing__content--card--amount {
  //         gap: 10px;
  //         width: 100%;
  //         display: flex;
  //         // flex-wrap: wrap;

  //         h2 {
  //           font-weight: 700;
  //           font-size: 29px;
  //           text-wrap: nowrap;
  //           margin: auto;
  //         }

  //         .pricing__content--card--amount--details {
  //           width: 100%;
  //           label {
  //             font-family: a.$inter;
  //             font-weight: 600;
  //             font-size: 12px;
  //             margin: 0;
  //           }

  //           p {
  //             margin: 0;
  //             font-family: a.$inter;
  //             font-weight: 500;
  //             font-size: 11px;
  //           }
  //         }
  //       }
  //     }

  //     .pricing__content--card--key__features {
  //       h5 {
  //         font-size: 14.62px;
  //         font-weight: bold;
  //         font-family: a.$rubik;
  //         color: #2b1c50;
  //       }

  //       ul {
  //         li {
  //           font-size: 14px;
  //           color: #111019;
  //         }
  //       }
  //     }
  //   }

  .pricing-body {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    align-self: center;

    @media (max-width: 600px) {
      flex-direction: column;
      gap: 20px;
      align-items: stretch;
    }

    .col {
      flex: 1 1 auto; /* Let the columns grow and shrink as needed */
      min-width: 250px; /* Set a minimum width for the columns */

      &:nth-child(-n + 3) {
        /* Select the first three cards */
        flex: 0 0 250px; /* Set a fixed width for the first three cards */
      }

      @media (max-width: 600px) {
        &:nth-child(-n + 3) {
          /* Reset the width for the first three cards on smaller screens */
          flex: 1 1 auto;
        }

        flex-basis: 100%; /* Reset the basis for full width on smaller screens */
        min-width: auto; /* Remove the minimum width on smaller screens */
      }

      &:last-child {
        flex: 0 0 250px;

        @media (max-width: 600px) {
          margin-top: 20px;
        }
      }
    }

    // .col {
    //   flex: 1 1 250px;
    //   max-width: 100%;
    // }

    // .col:nth-child(2n + 1) {
    //   margin-bottom: 20px;
    // }

    // @media (max-width: 600px) {
    //   .col:nth-child(3n + 1) {
    //     margin-top: 0px;
    //   }
    // }
  }

  h4,
  h5 {
    color: #fff;
    text-align: center;
  }

  h4 {
    font-size: 32px;
    margin: 0;
    font-weight: 700;
    // font-size: 3vw;

    @media (max-width: 600px) {
      font-size: 21px;
    }
  }

  h5 {
    font-weight: 400;
    font-size: 25px;

    @media (max-width: 600px) {
      font-size: 18px;
    }
  }

  .subs-card {
    color: #000;
    border-radius: 20px;
    min-height: 380px;
    overflow: hidden;
    position: relative;
    // width: 250px;

    @media (max-width: 600px) {
      width: 100%;
    }

    .subs-card__elite {
      width: 180px;
      border-radius: 0px 20px 20px 0px;
      position: absolute;
      top: 20px;
      left: 0;
      background-color: #8046fa;
    }

    h5 {
      font-weight: 600;
      font-size: 25px;
      // margin-top: 1rem;
      color: #000;
      margin-bottom: 0.5rem;
    }

    ul,
    li {
      list-style: none;
      padding: 0;
      font-size: 0.75rem;
      line-height: 1.8;
      font-weight: 400;

      span {
        font-weight: 700;
        color: #626262;
      }
    }

    .subs-card__payment-mod {
      bottom: 20px !important;
    }

    .subs-card__payment {
      h3 {
        font-size: 25px;
      }
      .subs-card__basic {
        height: 60px;
        width: 100%;
        background-color: #ff4df8;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 20px 0px;
      }

      p {
        text-align: left;
        line-height: 1.4;
        font-size: clamp(0.5rem, 2.5vw, 0.7rem);
        margin: 0;
      }

      button {
        margin-top: 1rem;
        background-color: #8046fa;
        color: #fff;
        border: 0;
        border-radius: 10px;
      }
    }
  }

  .card-elite {
    position: relative;
    border: 8px solid transparent;
    border-radius: 20px;
    background: linear-gradient(to right, white, white),
      linear-gradient(180deg, #ff4df8 0%, #8046fa 100%);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;

    overflow: visible;

    @media (max-width: 600px) {
      top: 0 !important;
    }

    .hiresmarter {
      .hiresmarter-bg {
        position: absolute;
        top: -6.5%;
        left: 50%;
        width: 70%;
        height: 10%;
        transform: translateX(-50%);
        z-index: 2;

        @media (max-width: 600px) and (min-width: 300px) {
          top: -10%;
          height: 15%;
        }
      }

      .hiresmarter-left {
        position: absolute;
        top: -6.4%;
        left: 45%;
        width: 70%;
        transform: translateX(-50%);
        z-index: 0;

        @media (max-width: 600px) and (min-width: 300px) {
          top: -10.2%;
          height: 8%;
        }
      }

      .hiresmarter-right {
        position: absolute;
        top: -6.4%;
        left: 55%;
        width: 70%;
        transform: translateX(-50%);
        z-index: 0;

        @media (max-width: 600px) and (min-width: 300px) {
          top: -10.2%;
          height: 8%;
        }
      }

      .hiresmarter-label {
        position: absolute;
        top: -16px;
        left: 50%;
        width: 50%;
        transform: translateX(-50%);
        z-index: 3;

        @media (max-width: 600px) and (min-width: 300px) {
          top: -8.5%;
          height: 10%;
          width: 55%;
        }
      }
    }

    h5 {
      font-size: 25px;
      color: #ff4df8;
      font-weight: 700;
      // margin-bottom: 10px;
    }
  }
  .subs-card-elite-cont {
    .subs-card__payment {
      .subs-card__basic {
        // margin-bottom: 20px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
  .sign__up__now {
    display: flex;
    justify-content: center;

    button {
      // margin-top: 1rem;
      background-color: #ff4df8;
      color: #fff;
      border: 0;
      border-radius: 10px;
      padding: 10px 35px;

      @media (max-width: 830px) {
        width: auto;
      }
    }
  }
  p {
    font-size: 28px;

    a {
      text-decoration: none;
      color: #ff4df8;
    }
  }
}
