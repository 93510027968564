@import "../Modal/CommonModalStyle.scss";

.modal-body-container {
  .name-validity {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;

    div {
      width: 100%;
    }
  }
}
