@use "../abstract/" as a;

.footer--container {
  background-color: a.$pink2;
  height: 115px;
  width: 100%;
  margin: 0px;
  display: flex;
  /* z-index: 10; */
  position: relative;

  .footer-container__body {
    font-weight: 600;
    font-size: 18px;
    padding-top: 40px;
    padding-bottom: 20px;
    padding-left: 20px;
    color: #fff;

    .logo-container {
      display: flex;

      background-color: a.$pink2;
      margin: 4px;
      margin-left: 0px;
      margin-right: 0px;
      padding-right: 0px;
      // background-color: a.$white;
    }

    .logo-container__icon {
      background-color: a.$white;
      color: a.$pink2;
      width: 30px;
      margin: 2px;
      text-align: center;
      line-height: 30px;
      font-size: 20px;
      border-radius: 5px;
    }
  }

  .ig-icon {
    color: a.$yellow2;
  }

  p {
    color: #fff;
    font-weight: 395;
    font-size: 19px;
    position: absolute;
    left: 15%;
    top: 65%;

    @media (max-width: 375px) {
      left: 17%;
    }
    @media (max-width: 360px) {
      left: 19%;
    }
  }
}
