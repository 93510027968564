.groupTable {
  background-color: #ffff;
  border-radius: 5px;

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 20px;

    input {
      width: 300.61px;
      border: 2px solid #c9c9c9;
      font-size: 20px;
    }

    input::placeholder {
      font-size: 20px;
    }
  }

  .tableContainer {
    .table {
      font-family: "Poppins", sans-serif;
      thead {
        background-color: #eae9f0;
        th {
          padding: 15px;
          text-align: center;
          font-size: 20px;
          font-weight: 600;
        }
      }

      tbody {
        td {
          font-size: 20px;
          text-align: center;
          font-weight: 500;
          margin: auto 0;
        }

        .contentText {
          padding-top: 15px;
        }

        .actionButtons {
          .icon {
            cursor: pointer;
            margin: auto 2px;
            color: #fff;
            background-color: #1d9ce6;
            padding: 10px;
            border-radius: 5px;
          }

          .iconTrash {
            cursor: pointer;
            margin: auto 2px;
            color: #fff;
            background-color: #d10000;
            padding: 10px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

.addAdminTable {
  border-radius: 3px;
  box-shadow: 0px 3px 10px 0px #bfbfbf29;
}
