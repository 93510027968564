@use "../../Abstract/" as a;

.hired_container_ats {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .hired_container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-family: a.$poppins;
    box-shadow: 0px 4px 4px 0px #00000040;

    .hired_container_information {
      display: flex;
      gap: 20px;
      .hired_img_section {
        img {
          width: 40px;
          height: 40px;
          border-radius: 100%;
        }
      }
      .hired_txt_section {
        h1 {
          color: #1d9ce6;
          font-weight: 500;
          font-size: 22px;
        }

        .hired_txt_section_date {
          display: flex;
          align-items: center;
          gap: 7px;
          color: #003863;
          font-size: 14px;

          .hired_icon {
            margin: 0;
          }

          p {
            margin: 0;
          }
        }

        .hired_txt_sentence {
          font-size: 14px;
        }
      }
    }
    .hired_view_section {
      display: flex;
      align-items: center;

      .icon {
        cursor: pointer;
      }
    }
  }
}
