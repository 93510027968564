.nxtLogo {
  cursor: pointer;
}

.landingNav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px #00000040;
}
.landingNavMenu {
  .act-nav {
    color: #6f64f8 !important;
    text-decoration: underline;
    text-decoration-color: #6f64f8;
    text-underline-offset: 8px;
    text-decoration-thickness: 1.5px;
  }

  .nav-link,
  .dropdown-item {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    color: #003763;
    font-weight: 700;
    text-transform: uppercase;
  }

  .dropdown-menu {
    background-color: #fff;
    border: 1px solid #fafafa;
    box-shadow: 0px 4px 4px 0px #00000040;

    @media screen and (max-width: 767px) {
      border: none;
      box-shadow: none;
    }
  }
}
