.custom_ats_table {
  text-align: center;

  thead {
    text-align: center;

    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 22px;

    th {
      background-color: #eae9f0;
    }
  }

  tbody {
    td {
      background-color: #fff;
    }

    .custom_ats_table_eye_icon {
      cursor: pointer;
    }
  }
}
