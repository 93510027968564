.status-history__fixedsize {
  max-height: 400px;

  .view_resume_history {
    .view_resume_history_content {
      max-height: 330px;
      overflow-y: auto;

      margin: 20px 0;
      p {
        margin: 5px 0;
        color: #333333;
        font-weight: 500;
        font-size: 16px;

        span {
          font-weight: 700;
        }
      }

      .line-seperator {
        margin: 0;
        border-top: 1px solid #e3e3e3;
      }
    }
  }
}
