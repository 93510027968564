@import "../Modal/CommonModalStyle.scss";

.modal-body-container {
  p {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #6f6f6f;
  }

  .skill-group {
    display: flex;
    gap: 10px;

    .skill-text {
      flex-shrink: 0.8;
    }
    .year {
      flex-shrink: 1.05;
    }
  }

  h6 {
    background: none;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #003763;
    cursor: pointer;
  }

  .deleteBtn {
    background: red;
    border: none;
  }

  .deleteBtn:hover,
  .deleteBtn:active {
    background: red;
    border: none;
  }
}
