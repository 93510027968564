@use "../Abstract/" as a;

.mostRecentJob {
  background-color: white;
  padding: 50px 0;
  display: flex;
  flex-direction: column;

  .mostRecentJobTitle {
    text-align: center;

    h1 {
      color: a.$violet3;
      font-family: a.$helvetica;
      margin-bottom: 50px;
    }
  }

  .mostRecentJobList {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    @media screen and (max-width: 768px) {
      flex-wrap: nowrap;
      flex-direction: column;
    }

    .container {
      flex: 1 0 calc(25% - 30px);
      display: flex;
      gap: 20px;
      padding: 20px;
      background-color: #dfe7ff;
      box-shadow: 0px 2px 4px 0px #00000026;
      border-radius: 4px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .imgSection {
        @media screen and (max-width: 768px) {
          display: flex;
          // width: 100px;
          height: 100%;
          justify-content: center;
        }
        img {
          border-radius: 7px;
          width: 40px;
          height: 40px;

          @media screen and (max-width: 768px) {
            width: 100px;
            height: 100%;
          }
        }

        // .errorImg {
        //   background-color: #d9d9d9;
        //   padding: 10px;

        //   @media screen and (max-width: 768px) {
        //     width: 100px;
        //   }
        // }
      }

      .detailsSection {
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 5px;

        h1 {
          cursor: pointer;
          font-family: a.$lato;
          font-size: 18px;
          font-weight: 800;
          color: a.$dblue3;
          margin: 0;
        }

        h2 {
          font-family: Lato;
          font-size: 16px;
          font-weight: 600;
          color: #232323;
        }

        .cityWorkArrangement {
          flex-wrap: wrap;
          display: flex;
          gap: 10px;
          div {
            color: a.$dblue3;
            display: flex;
          }
        }

        .calendar {
          color: a.$dblue3;
          display: flex;
        }

        p {
          font-family: a.$lato;
          font-size: 14px;
          font-weight: 500;
          color: a.$dblue3;
          margin: 0;
          padding: 0;
        }

        .icon {
          margin-right: 10px;
        }
      }
    }

    .btnContainer {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
