.appliedjobtable-container {
  padding: 30px;
  background: #ffffff;
  border: 1px solid #00000040;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;
  margin: 0 0 20px 0;

  .header {
    h1 {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      color: #003763;
    }
  }

  .show-search {
    display: flex;
    justify-content: space-between;

    .show-group {
      display: flex;
      gap: 5px;

      label {
        margin: auto 0;
      }
    }

    .search-group {
      display: flex;
      gap: 5px;

      label {
        margin: auto 0;
      }
    }
  }

  .job-table-container {
    margin-top: 20px;
    // overflow: scroll;

    // width: 100%;
    .job-table {
      table-layout: fixed;
      max-width: 100%;
      // table-layout: fixed;
      width: 100%;
      thead {
        tr {
          // width: 100%;
          background: #e1e7ff;

          th {
            font-family: "Lato", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #000000;

            label {
              margin-left: 10px;
            }
          }
        }
      }

      tbody {
        td {
          // min-width: 200px;
          // display: flex;
          padding: 10px;
          align-items: center;
          padding-left: 25px;

          margin: auto;
          //   text-align: center;
          //

          .jobtitle-container {
            .job-title {
              font-family: "Lato", sans-serif;
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              color: #000000;
            }
            .job-title:hover {
              color: #3085d6;
              cursor: pointer;
            }
            .location-container {
              display: flex;
              gap: 10px;

              div {
                label {
                  margin-left: 10px;
                }

                .icon {
                  color: #004276;
                }
              }
            }
          }
        }

        .view-btn {
          background: #3085d6;
          border-radius: 3px;
          border: none;
        }
      }
    }
  }

  .entries-pagination-container {
    display: flex;
    justify-content: right;

    p {
      margin: auto 0;
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #6f6f6f;
    }

    button {
      background: #efbb20;
      border: none;
      border-radius: 3px;

      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
    }
  }
}
