.landing-career {
  background-color: #ffffff;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 30px 0;

  h1 {
    margin-bottom: 25px;
    color: #8046fa;
    font-family: "Rubik";
    font-size: calc(1.3rem + 0.6vw);
  }

  .landing-card-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
    .card-img {
      width: 100%;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .landing-card {
      box-shadow: none;

      .landing-card-body {
        padding: 20px;
        min-height: 200px;
        height: 200px;
        background-color: #f5f5f5;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        h5 {
          font-family: "Rubik";
          color: #8046fa;
          font-weight: 600;
          font-size: calc(1.275rem + 0.3vw);
          @media (min-width: 768px) and (max-width: 992px) {
            font-size: 15px;
          }
          //   @media (max-width: 768px) {
          //     font-size: calc(1.275rem + 0.3vw);
          //   }

          //   @media (max-width: 300px) {
          //     font-size: 15px;
          //   }
        }

        p {
          font-family: "Rubik";
          font-size: 14px;

          @media (min-width: 768px) and (max-width: 992px) {
            font-size: 12px;
          }

          //   @media (max-width: 768px) {
          //     font-size: 14px;
          //   }

          //   @media (max-width: 300px) {
          //     font-size: 12px;
          //   }
        }
      }
    }
  }
}
