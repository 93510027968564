@use "../../Abstract/" as a;

.customContainer {
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}

.aboutUsLinkContainer {
  padding: 200px 40px 30px 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;

  @media screen and (max-width: 768px) {
    padding: 300px 40px 30px 40px;
  }
}

.customContainerLogin {
  height: 100vh;
  // overflow-x: scroll;
  overflow-y: scroll;
}

.landingFooterContainer {
  background-position: center;
}

.getInTouchContainer {
  background-color: #eff0f0;
}

.customContainerWhiteBg {
  background-color: a.$white;
}

.customContainerAdminLogin {
  height: 100vh;
}

.adminNavBg {
  background-color: a.$violet;
  height: fit-content;
  padding: 0 10px;
  position: fixed;
  width: calc(100% - 274px);

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
}

.containerPadding {
  background-color: #fff;
  padding: 70px;

  @media screen and (max-width: 768px) {
    padding: 30px 0;
  }
}

.containerWhiteBg {
  background-color: #fff;
}

.login-status__employer {
  position: absolute;
  font-size: 2.5rem;
  color: #8046fa;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  bottom: 7%;
  left: 32%;

  @media (min-width: 1200px) {
    bottom: 12%;
  }
}

.float__alert {
  position: absolute;
  // font-size: 2.5rem;
  color: #8046fa;
  // font-weight: 700;
  width: 100%;
  font-family: "Poppins", sans-serif;
  // bottom: 7%;

  left: 0%;
  // padding: 0 20px;
  z-index: 99;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
