.ats__dashboard {
  font-family: Poppins;

  .ats__dashboard-card {
    background: #fff;
    box-shadow: 0px 2px 8px 0px #00000022;
    border-radius: 5px;
    min-height: 310px;
    position: relative;
  }

  .overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    border-radius: 5px;
    opacity: 1;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.6); /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    transition: 0.5s ease;
    font-size: 20px;
    padding: 20px;
    text-align: center;
  }

  .ats__dashboard-circle-jobPosted {
    width: 62px;
    height: 62px;
    background: #e6f7ee;
    border-radius: 50%;
    .ats__dashboard-icon {
      font-size: 30px;
      color: #2dcd7a;
    }
  }

  .ats__dashboard-circle-talentApplied {
    width: 62px;
    height: 62px;
    background: #d5d1fb;
    border-radius: 50%;
    .ats__dashboard-icon {
      font-size: 30px;
      color: #7e72f2;
    }
  }

  .ats__dashboard-circle-company {
    width: 62px;
    height: 62px;
    background: #ffdfc2;
    border-radius: 50%;
    .ats__dashboard-icon {
      font-size: 30px;
      color: #ff9f43;
    }
  }

  .ats__dashboard-circle-draft {
    width: 62px;
    height: 62px;
    background: #ffc7c7;
    border-radius: 50%;
    .ats__dashboard-icon {
      font-size: 30px;
      color: #ff0000;
    }
  }

  .ats__dashboard-circle-pooling {
    width: 62px;
    height: 62px;
    background: #ccf7ff;
    border-radius: 50%;
    .ats__dashboard-icon {
      font-size: 30px;
      color: #0dcaf0;
    }
  }

  .ats__dashboard-circle-hired {
    width: 62px;
    height: 62px;
    background: #c4e5ff;
    border-radius: 50%;
    .ats__dashboard-icon {
      font-size: 30px;
      color: #2c97eb;
    }
  }

  .ats__dashboard-modnumber {
    font-family: "Montserrat", sans-serif;
    line-height: 1.2;

    .ats__dasboard-title {
      font-size: clamp(1rem, 6.5vw, 1.4rem);
    }
    span {
      font-weight: 700;
      color: #606060;
      font-size: clamp(1.2rem, 6.5vw, 2.2rem);
    }
  }

  .tooltip-dashboard {
    border: 1px solid #000;
    border-radius: 50%;
    font-size: 0.7rem;
    height: 18px;
    width: 18px;
  }

  h3 {
    font-size: 48px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    color: #6f64f8;
  }
}
