.key-skills-container {
  width: 100%;
  height: 100%;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #00000040;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;
  margin: 0 0 20px 0;

  h6 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #003763;
  }

  label {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #003763;
    word-break: break-all;
    white-space: pre-line;
  }

  .select-group {
    margin-bottom: 20px;
    .keyskills-list {
      // display: flex;
      gap: 20px;
      display: flex;
      flex-wrap: wrap;
      .keyskill-container {
        color: #ffff;
        background: #0dcaf0;
        text-align: center;
        border-radius: 2px;
        display: flex;
        padding: 10px 20px;
        min-width: fit-content;
        word-break: break-all;
        white-space: pre-line;

        p {
          margin: auto;
          font-family: "Lato", sans-serif;
          font-weight: 600;
          font-size: 14px;
        }
      }

      // p {
      //   font-family: "Lato", sans-serif;
      //   font-weight: 600;
      //   font-size: 14px;
      //   max-width: fit-content;
      //   color: #ffff;
      //   background: #0dcaf0;
      //   padding: 8px 30px;
      //   text-align: center;
      //   border-radius: 2px;
      //   // word-break: break-all;
      //   // white-space: pre-line;
      // }
    }
  }

  .skills-not-available {
    width: 100%;
  }
}
