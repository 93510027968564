@use "../../../Abstract/" as a;

.custom_offcanvas {
  --bs-offcanvas-width: 70% !important;
  //   --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 30px;
  --bs-offcanvas-padding-y: 30px;
  //   --bs-offcanvas-color: green;
  --bs-offcanvas-bg: #fbfbfb !important;
  //   --bs-offcanvas-border-width: 6px;
  //   --bs-offcanvas-border-color: green;
  //   --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

  .custom_offcanvas_header {
    background-color: #6f64f8;
    font-family: a.$poppins;
    color: #fff;

    .custom_offcanvas_title {
      font-weight: 400;
    }
    .custom_offcanvas_icon {
      font-size: 25px;
      cursor: pointer;
    }
  }
}
