.manage-job__table {
  .ats_action_container {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
    .edit-container {
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: 5px;
      padding: 8px;
      background-color: #3085d6;

      .edit_icon {
        font-size: 20x;
      }
    }

    .delete-container {
      display: flex;
      align-items: center;
      border-radius: 5px;
      padding: 6.3px;
      background-color: #e80000;

      .delete_icon {
        font-size: 20px;
      }
    }
  }

  .ats_status_select {
    border-radius: 15px;
    border: 1px solid #979797;

    .status-circle {
      color: yellow;
      background-color: yellow;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
