.st-work-history-container {
  width: 100%;
  height: 100%;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #00000040;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;
  margin: 0 0 20px 0;

  h6 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #003763;
  }

  label {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #333333;
  }

  //   p {
  //     line-height: 10px;
  //   }
  .work-history-list {
    margin-top: 20px;
    p {
      line-height: 10px;
    }

    .remove-icon-container {
      cursor: pointer;
      margin-top: -10px;
      .remove-work {
        width: 10px;
        height: 11px;
        color: #333333;
      }

      span {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #333333;
      }
    }
  }
}
