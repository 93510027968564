.addAdminForm {
  display: flex;
  gap: 20px;
  input,
  select {
    font-size: 20px;
    border: 2px solid #c9c9c9;
    border-radius: 5px;
  }

  input::placeholder {
    font-size: 20px;
  }
}
