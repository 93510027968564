@import "../../../Abstract/fonts";

.invitationMessage {
  margin-top: 10px;
  // height: 100px !important;
  border: 1px solid hsl(0, 0%, 80%);
}

.invitationMessage::placeholder {
  font-size: 15px;
  color: hsl(0, 0%, 50%);
  font-family: $lato;
}
