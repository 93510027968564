@use "../../../Abstract/" as a;

.subscriberCardInfo {
  display: flex;
  gap: 30px;

  @media screen and (max-width: 1260px) {
    flex-direction: column;
  }

  h1 {
    font-family: a.$poppins;
    font-size: 20px;
    font-weight: 600;
    color: #002848;
  }

  p {
    font-family: a.$poppins;
    font-size: 16px;
    font-weight: 400;
  }

  .statisticsSection {
    margin-top: 30px;
    display: flex;
    gap: 10px;
    text-align: center;

    h2 {
      font-family: a.$poppins;
      font-size: 14px;
      font-weight: 400;
      color: #fff;
    }

    .hired,
    .inprogress,
    .dropped {
      width: 90px;
      height: 100%;

      h2 {
        background-color: #1d9ce6;
        padding: 5px 0;
        margin: 0;
        border-radius: 5px 5px 0px 0px;
        white-space: nowrap; /* Prevent line breaks */
      }

      p {
        padding: 0;
        margin: auto 0;
        border: 1px solid #333333;
        border-top: none;
        border-radius: 0px 0px 5px 5px;
      }
    }

    .hired {
      h2 {
        background-color: #1d9ce6; /* Hired background color */
      }
    }

    .inprogress {
      h2 {
        background-color: #0e932b; /* In Progress background color */
      }
    }

    .dropped {
      h2 {
        background-color: #d10000; /* Dropped background color */
      }
    }
  }
}
