.view_resume_ats {
  display: flex;

  .view_resume_ats_section {
    flex: 1;
  }
}
.screen-score,
.match-score {
  h4 {
    color: #1d9ce6;
    font-family: "Lato", sans-serif;
    font-weight: 800;

    .screen-score__green {
      color: #0e932b;
    }
  }

  small {
    color: #000000;
    font-weight: 700;
  }

  p {
    font-family: "Rubik";
    font-size: 0.875rem;
  }

  .view-resume__table-height {
    max-height: 200px;
    overflow-y: auto;

    table {
      font-size: 0.85rem;
    }

    th {
      background-color: #eae9f0 !important;
    }
    td {
      background-color: #fff !important;
    }
  }

  .accordion {
    .accordion-item button {
      background-color: #fff !important;
      color: #1d9ce6 !important;
      padding: 1rem 0;
    }

    .card-header {
      background-color: #fff !important;
      h4 {
        color: #1d9ce6 !important;
        padding: 1rem 0;
        font-size: 0.85rem;
      }
    }

    .accordion-button::after {
      display: none;
    }

    .accordion-collapse {
      background-color: #fff;
      .accordion-body {
        padding: 1rem 0;
      }
      h3 {
        font-size: 0.85rem;
        background-color: #eae9f0;
      }
      p {
        font-size: 0.85rem;
      }
    }
  }

  .match-score__btn {
    background-color: #1d9ce6;
    border: 0;
  }

  .screen-score__circle {
    max-height: 90px;
    max-width: 90px;
    text {
      font-size: 26px;
      color: #30adf3 !important;
      font-weight: 700;
    }
  }
}
