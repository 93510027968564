@use "../../../Abstract/" as a;

.subscriptionContent {
  font-family: a.$montserat;
  display: flex;
  gap: 20px;

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }

  .subscriptionDetails {
    width: 100%;
    box-shadow: 0px 12px 28px 0px #8c959f4d;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: #fff;
    height: 500px;

    @media screen and (max-width: 1146px) {
      height: 700px;
    }

    @media screen and (max-width: 992px) {
      height: 100%;
    }

    .planTitle {
      padding: 20px;
      text-align: center;

      h1 {
        font-size: 24px;
        font-weight: 700;
      }

      .price {
        display: flex;
        gap: 10px;
        justify-content: center;

        .icon {
          color: #57606a;
          font-size: 20px;
          font-weight: 100;
        }

        h2 {
          font-size: 48px;
          font-weight: 400;
        }
      }

      h3 {
        font-size: 12px;
        font-weight: 400;
        color: #57606a;
        span {
          color: #0969da;
        }
      }
    }

    .buttonContainer {
      padding: 0px 20px 25px 20px;
    }

    .subscriptionAdditionalDetailsContainer {
      padding: 20px;
      height: 100%;
      background-color: #f6f8fa;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .subscriptionAdditionalDetails {
        display: flex;
        gap: 10px;
        align-items: center;
        font-size: 13px;
        font-weight: 700;

        p {
          margin: auto 0;
        }
      }
    }
  }
}

.activePlan {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #6f64f8 0%,
    #6f64f8 48%,
    #8c64d2 85%,
    #8c64d2 98%
  );

  padding: 10px;
  border-radius: 8px;
  width: 100%;
  height: 100%;

  .activeText {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 10px;
    height: 20px;
  }
}

.inactivePlan {
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  height: 100%;

  .activeText {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 10px;
    height: 20px;
  }
}
