@use "../../../Abstract/" as a;

.contactUsForm {
  padding: 30px 0;
  font-family: a.$rubik;

  h1 {
    font-weight: 600;
    font-size: 48px;
    color: a.$violet3;
    text-align: center;
  }

  h2 {
    font-weight: 400;
    font-size: 32px;
    color: #232323;
    text-align: center;
  }
  .form {
    .formSection {
      display: flex;
      gap: 30px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 0px;
      }
      .formInputGroup {
        width: 100%;
      }
    }

    .formController {
      padding: 20px 0;
      margin-bottom: 20px;
    }

    .customButtonContainer {
      display: flex;
      justify-content: center;
    }
  }
}
