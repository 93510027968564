.work-history-talent {
  width: 100%;
  height: 100%;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #00000040;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;
  margin: 0 0 20px 0;

  h6 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #003763;
  }

  label {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #003763;
  }

  .work-history-list-container {
    max-height: 300px;
    overflow-y: auto;
    .work-history-list {
      margin-top: 0px;
      margin-bottom: 20px;

      label {
        word-break: break-all;
        white-space: pre-line;
        font-weight: 700;
      }

      p {
        line-height: 15px;
      }

      .remove-work-container {
        display: flex;
        gap: 10px;
        margin-top: -10px;

        .remove-work {
          width: 10px;
          height: 11px;
          color: #333333;
          cursor: pointer;
        }

        span {
          font-family: "Lato", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          color: #333333;
          cursor: pointer;
        }

        button {
          font-family: "Lato", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          color: #ffffff;
          background: #efbb20;
          border-radius: 3px;
          border: none;
        }
      }
    }
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    // background-color: aqua;

    h6 {
      align-items: center;
      margin: auto 0;
    }

    button {
      align-items: center;
      margin: auto 0;
      background: #003763;
      border-radius: 3px;
      border: none;
    }
  }

  .btn-workhistory_cancel {
    border: 1px solid #003763;
    border-radius: 3px;
    color: #003763;
    background-color: #fff;

    &:hover {
      background-color: #fff;
      color: #003763;
    }
  }

  .btn-workhistory_save {
    background: #003763;
    border-radius: 3px;

    &:hover {
      background: #003763;
      border-radius: 3px;
    }
  }
}

@media screen and (max-width: 900px) {
  .work-history-talent {
    .header-container {
      display: block;

      button {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}
