.customCardWithIcon {
  display: flex;
  gap: 10px;

  .cardWithIcon {
    width: 100%;
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 5px;

    .cardIconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 62px;
      height: 62px;
      // background-color: aquamarine;
      border-radius: 50%;

      .icon {
        font-size: 30px;
      }
    }

    .cardTextContainer {
      padding: 10px 10px 0px 10px;

      h1 {
        font-family: "Poppins", sans-serif;
        font-size: 24px;
        font-weight: 600;
        margin: 0;
      }

      p {
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        font-weight: 400;
        margin: 0;
      }
    }
  }
}
