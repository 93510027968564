@use "../../../Abstract/" as a;

.emailRegStep2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;

  .verifyCodeContainer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;

    input {
      background-color: a.$gray4;
      border: none;
      border-radius: 5px;
      padding: 10px 20px;
    }

    input:focus {
      outline: none;
    }
  }

  .resendEmail,
  .verifyCode {
    font-size: 14px;
    color: a.$lblue4;
    cursor: pointer;
  }
}

.emailRegStep3 {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
