.background-imagePost-appledJob {
  background-image: url("../../../../../public/images/acquiro-element-bg.jpg") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  .header-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
  }

  .header-fixed + .jobposting-banner {
    padding-top: 90px;
  }

  .applied-job-body {
    // max-width: 100%;
    margin: 50px auto;
    display: flex;
    // background-color: aqua;
    flex-direction: row;
    // margin: 50px auto;
    // padding: 0 auto;
    .applied-job-body-inner {
      //   overflow: hidden;
      //   flex-shrink: 1;
      gap: 50px;
      display: flex;
      width: 100%;
      .sidebar-container {
        top: 100px;
        position: sticky;
      }

      .company-profile-container {
        max-width: 100%;
        height: 100%;
        // background-color: aqua;
        // display: inline-block;
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 20px 0;

    .side-bar-menu {
      align-self: auto;
      width: fit-content;
      height: fit-content;
      margin-left: 18%;
    }
  }
}

@media screen and (max-width: 992px) {
  .background-imagePost-appledJob {
    .applied-job-body {
      .applied-job-body-inner {
        display: block;
        .sidebar-container {
          width: 100%;
          margin: auto;
          margin-bottom: 20px;
          .side-bar {
            width: 100%;
          }
        }
      }
    }
  }
}
