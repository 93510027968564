@use "../abstract/" as a;
/*					
  Alvin Paala
  Charmaine Manga
*/

.jobSeeker__body {
  color: a.$white;
  background-color: a.$lblue2;
  font-family: "Rubik";
  width: 100%;

  @media (max-width: 375px) {
    width: 105%;
  }

  @media (max-width: 320px) {
    width: 105%;
  }
  .title-name__container {
    padding: 45px;
    padding-bottom: 0px;
    z-index: 2;
    position: relative;
  }

  h1 {
    font-weight: 400;
    font-size: 35px;
    text-align: center;
  }

  h2 {
    font-weight: 600;
    font-size: 55px;
    text-align: center;
    margin-top: 0px;

    @media (max-width: 375px) {
      font-size: 48px;
    }
    @media (max-width: 320px) {
      font-size: 43px;
    }

    img {
      width: 75px;
      height: 75px;
      position: absolute;
      left: 81%;
      top: 34%;

      @media (max-width: 375px) {
        width: 55px;
        height: 55px;
        position: absolute;
        left: 81%;
        top: 45%;
      }
      @media (max-width: 320px) {
        width: 55px;
        height: 55px;
        position: absolute;
        left: 80%;
        top: 45%;
      }
    }
  }
  .button__container {
    text-align: center;
  }
  .signup--button {
    padding: 15px 32px;
    background-color: #8046fa;
    border: none;
    color: #ffffff;
    text-align: center;
    margin: 4px 2px;
    font-weight: 550;
    font-size: 25px;
    border-radius: 25px;
    font-family: "Rubik";
    z-index: 2;
    position: relative;
  }

  .para__container {
    font-weight: 390;
    font-size: 20px;
    text-align: center;
    padding: 10px;
    margin-top: 50px;
    z-index: 3;
    position: relative;

    @media (max-width: 320px) {
      right: 2%;
    }
  }

  .dots__img {
    width: 100%;
    position: absolute;
    z-index: 1;
  }

  .bottom--bg__container {
    margin-top: 70px;
    position: relative;
    .sunset__bg {
      width: 100%;
      height: auto;
      position: absolute;
      bottom: -5px;
    }

    .girl {
      width: 100%;
      bottom: -5px;
      height: auto;
      position: relative;
    }
  }
}
