@use "../../Abstract/" as a;

.customInput {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 5px;

  label {
    font-family: a.$inter;
    font-size: 12px;
    font-weight: 600;
    color: a.$lblue4;
  }

  input,
  select {
    background-color: a.$gray4;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
  }

  input:focus,
  select:focus {
    outline: none;
  }

  // Textarea
  textarea {
    width: 100%;
    height: 324px !important;
    background-color: a.$gray4;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
  }

  textarea:focus {
    outline: none;
  }

  .formInputGroupWithBorder {
    border: 1px solid #dfdede;
  }
}
