.dndColumn {
  display: flex;
  flex-direction: column;
  // height: calc(100% - 60px);
  // overflow-x: scroll;
  h2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    padding: 0 15px;
  }

  .dndcolumn-circle_label {
    line-height: 1.5;
    height: 30px;
    width: 30px;
    background: #1d9ce6;
    border-radius: 50%;
    font-size: 20px;
  }

  .bucketContainer {
    background-color: #f2f2f235;
    padding: 4px;
    width: 360px;
    height: 600px;
    overflow-y: scroll;

    // height: 100%;
    // background: #1d9ce6;

    .bucketInnerContainer {
      padding: 16px;
      margin: 0 0 8px 0;
      min-height: 50px;
      box-shadow: 0px 4px 4px 0px #00000040;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }
}

.card-tooltip {
  &.show {
    opacity: 1 !important;
    padding: 2px;
    z-index: 1 !important;

    .bs-tooltip-end .tooltip-arrow::before,
    .tooltip-arrow::before {
      border-right-color: #6f64f8 !important;
      right: -3px !important;
    }
  }

  .tooltip-inner {
    color: #ffff !important;
    background-color: #6f64f8 !important;
    padding: 10px;
    font-family: "Montserrat";
    font-style: italic;
    text-align: left;
  }
}
