@use "../../../Abstract/" as a;

.chooseType {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: a.$inter;

  h1 {
    font-size: 30px;
    font-weight: 700;
    color: #003763;
  }
  .clientButton,
  .talentButton {
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f6f6f9;
    border-radius: 3px;
    gap: 10px;

    img {
      width: 60px;
      height: 60px;
    }

    p {
      font-size: 20px;
      font-weight: 400;
    }

    .icon {
      color: a.$violet;
      font-size: 30px;
      padding: 0 5px;
    }
  }
  .customBtnContainer {
    width: 100%;
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
  }
}
