@use "../../../Abstract/" as a;

.adminLogin {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: a.$poppins;

  @media screen and (max-width: 768px) {
    height: 100vh;
    padding: 20px;
  }

  .adminLoginForm {
    padding: 50px;
    background-color: a.$white;
    display: flex;
    border-radius: 40px;
    box-shadow: 3px 6px 10px 0px #c0c0c040;
    gap: 60px;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 30px;
    }

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }

    .section {
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 40px;
        font-weight: 500;
        color: #333333;
        text-align: center;
      }

      p {
        font-size: 24px;
        font-weight: 400;
        color: #666666;
        text-align: center;
      }

      .formInputGroup {
        display: flex;
        flex-direction: column;

        label {
          margin-top: 20px;
          font-weight: 400;
          font-size: 16px;
          color: #666666;
        }

        input {
          width: 484px;
          height: 56px;
          background-color: a.$white;
          border: 1px solid #66666659;
          border-radius: 12px;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }

      .forgotPassButton {
        margin-top: 30px;
      }
    }

    .sectionImage {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px;
    }
  }
}
