.profile-container {
  width: 100%;
  height: 100%;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #00000040;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;

  .profile-name,
  .profile-address {
    display: flex;
    gap: 20px;

    .label-subText {
      width: 100%;
    }

    label {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #333333;
    }

    div {
      flex: 1 1 0;
      // width: 0;
    }

    input,
    select {
      border: 1px solid #e1e6eb;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
    }
  }

  .profile-contact {
    display: flex;
    gap: 20px;

    label {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #333333;
    }

    .email-container,
    .birthday-container,
    .phone-container {
      flex: 1 1 0;
      // width: 20%;
    }

    #contactNumber {
      width: 10%;
    }

    input,
    select {
      border: 1px solid #e1e6eb;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
    }
  }

  .update-talent-profile-btn {
    width: 100%;
    background: #efbb20;
    color: #ffffff;
    border: none;
    margin-top: 20px;
  }

  .update-talent-profile-btn:hover {
    background: #efbb20;
  }

  .profile-header {
    h1 {
      color: #003763;
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 800;
      font-family: "Lato", sans-serif;
    }

    div {
      width: 100%;
    }
  }
}

@media (max-width: 1000px) {
  .profile-container {
    width: 100%;
    .profile-name,
    .profile-contact,
    .profile-address {
      display: block;

      .formInput {
        width: 100%;
      }
    }
  }
}
