@mixin fix-style {
  font-family: "Rubik";
  background-color: #fff;
}

@mixin h5-style {
  color: #8046fa;
  font-size: 3rem;
  font-weight: 600;
}

@mixin p-style {
  font-size: 1.4rem;
  font-weight: 400;
}

.choose-plan {
  font-family: "Rubik";
  background-color: #fff !important;
  min-height: 110vh;

  h5 {
    @include h5-style();
    font-weight: 600;
  }

  .freemium {
    background-color: #eff0ff;
    width: 400px;
    border-radius: 44px;
    height: 700px;

    h4 {
      color: #2b1c50;
      font-size: 44px;
      letter-spacing: -3px;
    }
    label {
      color: #3d2e7c;
    }
    h3 {
      color: #2b1c50;
      font-weight: 400;
    }
    button {
      background-color: #7352e9;
      border: 0px;
      border-radius: 30px;
    }
  }

  .premium {
    background-color: #fff;
    width: 400px;
    border-radius: 44px;
    overflow: hidden;
    min-height: 750px;
    left: -50px;
    position: relative;

    .most-popular {
      background-color: #2b1c50;
      color: #fff;
    }
    h3 {
      color: #2b1c50;
      font-weight: 400;
    }
    h4 {
      color: #2b1c50;
      font-size: 44px;
      letter-spacing: -3px;
    }
    label {
      color: #3d2e7c;
    }

    button {
      background-color: #7352e9;
      border: 0px;
      border-radius: 30px;
    }
  }
}
