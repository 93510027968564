@use "../../../Abstract/" as a;

.titleLine {
  .titleBtns {
    h1 {
      font-family: a.$poppins;
      font-size: 20px;
      font-weight: 600;
      color: #002848;
      text-transform: uppercase;
    }
  }

  .lineSeparator {
    color: #e1e7ff;
  }
}

.ats_title_color {
  .titleBtns {
    h1 {
      font-family: a.$poppins;
      font-size: 20px;
      font-weight: 600;
      color: #1d9ce6;
    }
  }

  .lineSeparator {
    color: #e1e7ff;
  }
}
