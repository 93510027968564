.custom-modal-container {
  justify-content: center;

  .modal-content {
    width: 327px;
    height: fit-content;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;

    background: white;

    .custom-modal-body {
      .btnContainer {
        .cancelBtn {
          background-color: #ffff;
        }
      }
      .icon-container {
        width: 100%;
        display: flex;
        justify-content: center;
        .success-icon,
        .question-icon,
        .warning-icon {
          width: 95px;
          height: 95px;
          margin-top: -70px;
          background-color: #ffffff;
          border-radius: 100%;
          border: none;
        }

        .success-icon {
          color: #0e932b;
        }

        .question-icon {
          color: #efbb20;
        }

        .warning-icon {
          color: #e80000;
        }
      }

      .modal-title {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        color: #232323;
        margin: 40px 0;
        text-align: center;
      }

      .modal-sublabel {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #232323;
        text-align: center;
        min-height: 90px;
      }
      .modal-title-center {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 25px;
        color: #232323;
        margin: 80px 0;
        text-align: center;
      }

      .btnContainer {
        display: flex;
        gap: 10px;
        .cancelBtn {
          background-color: rgb(172, 172, 172);
        }
      }

      .success-btn,
      .question-btn,
      .warning-btn,
      .cancelBtn {
        border-radius: 3px;
        border: none;

        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #ffffff;
        width: 100%;
        // margin-bottom: 0;
      }

      .success-btn {
        background: #0e932b;
      }

      .question-btn {
        background: #efbb20;
      }

      .warning-btn {
        background: #e80000;
      }
      .close-button {
        margin-right: -15px;
      }
    }
  }
}
